<template>
  <Container tagName="header" :class="$style.heroWithTextOnly">
    <h1 :class="$style.heading">{{ content.heading }}</h1>
    <p v-if="content.subtitle" :class="$style.subheading">{{ content.subtitle }}</p>
    <HorizontalScroller>
      <div :class="$style.breadcrumb">
        <component
          v-for="({ title, path }, index) in breadcrumbs"
          :key="index"
          :is="index == breadcrumbs.length - 1 ? 'span' : 'a'"
          :href="path"
          >{{ title }}</component>
      </div>
    </HorizontalScroller>
  </Container>
</template>

<script>
import HorizontalScroller from '../elems/HorizontalScroller.vue'

export default {
  components: {
    HorizontalScroller,
  },
  props: {
    breadcrumbs: Array,
    content: Object,
  },
}
</script>

<style module>
.heroWithTextOnly {
  padding-top: var(--spacing-15);
  padding-bottom: var(--spacing-15);
  background-color: var(--bg-color-dark-blue);
  color: var(--text-color-white);
}

.heading {
  font-weight: 600;
  font-size: var(--font-size-11);
  line-height: 1.25;
}

.subheading {
  max-width: 36rem;
  margin-top: var(--spacing-0);
  font-weight: 400;
  font-size: var(--font-size-3);
  line-height: 1.5;
}

.breadcrumb {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  margin-top: var(--spacing-5);
  font-size: var(--font-size-1);
}
.breadcrumb > * {
  display: inline;
}
.breadcrumb a {
  color: inherit;
}
.breadcrumb a:hover {
  text-decoration: underline;
}
.breadcrumb > :nth-child(n + 2)::before {
  content: url('../../assets/images/breadcrumb-arrow.svg');
  display: inline-block;
  margin: 0 var(--spacing-3);
}

@media (--screen-lg) {
  .heading {
    line-height: 1.15;
  }
}
</style>
