<template>
  <div class="DefaultBlockContent" :class="{ useCheckIconBullets }">
    <BlockContent v-bind="{ serializers, ...$props}" />
  </div>
</template>

<script>
import BlockContent from 'sanity-blocks-vue-component'
import BlockContentActionLink from './BlockContentActionLink.vue'
import BlockContentCtaButton from './BlockContentCtaButton.vue'
import BlockContentIconList from './BlockContentIconList.vue'
import BlockContentTextLink from './BlockContentTextLink.vue'

const serializers = {
  marks: {
    link: BlockContentTextLink,
    actionLink: BlockContentActionLink,
  },
  types: {
    cta: BlockContentCtaButton,
    iconList: BlockContentIconList,
  },
}

export default {
  components: {
    BlockContent,
  },
  props: {
    blocks: {
      type: Array,
      default: Array,
    },
    useCheckIconBullets: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return { serializers }
  },
}
</script>

<style scoped>

.DefaultBlockContent {
  font-weight: normal;
  font-size: var(--font-size-3);
  line-height: 1.7;
  color: var(--text-color, var(--text-color-dark));
}

.DefaultBlockContent >>> h2,
.DefaultBlockContent >>> h3,
.DefaultBlockContent >>> h4,
.DefaultBlockContent >>> h5,
.DefaultBlockContent >>> h6 {
  color: var(--heading-color, var(--text-color-black));
  font-weight: 600;
  line-height: 1.2;
}

.DefaultBlockContent >>> * + h2,
.DefaultBlockContent >>> * + h3,
.DefaultBlockContent >>> * + h4,
.DefaultBlockContent >>> * + h5,
.DefaultBlockContent >>> * + h6 {
  margin-top: var(--spacing-10);
}

.DefaultBlockContent >>> * + p {
  margin-top: var(--spacing-6);
}

.DefaultBlockContent >>> * + ul {
  margin-top: var(--spacing-6);
}

.DefaultBlockContent >>> * + ol {
  margin-top: var(--spacing-6);
}

.DefaultBlockContent.useCheckIconBullets >>> ul {
  list-style: none;
}

.DefaultBlockContent.useCheckIconBullets >>> ul li {
  background-image: var(--bullet-image, url('../../assets/images/bullet-blue.svg'));
  background-position: 0 4px;
  background-repeat: no-repeat;
  padding-left: 28px;
}

.DefaultBlockContent.useCheckIconBullets >>> ul li + li {
  margin-top: var(--spacing-0);
}

.DefaultBlockContent >>> ul,
.DefaultBlockContent >>> ol {
  padding-left: 1.5rem;
  line-height: 1.5;
}

.DefaultBlockContent >>> ul {
  list-style: disc;
}

.DefaultBlockContent >>> ol {
  list-style: decimal;
}

.DefaultBlockContent >>> ol li + li {
  margin-top: var(--spacing-4);
}

.DefaultBlockContent >>> a {
  text-decoration: underline;
}

.DefaultBlockContent >>> a:hover {
  color: var(--text-link-hover-color, var(--brand-color));
}

@media (--screen-lg) {

  .DefaultBlockContent >>> ul li {
    background-position: 0 6px;
  }
}
</style>
