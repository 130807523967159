<template>
  <Container tagName="section" class="CenteredContentSection">
    <div class="mediaChunk" :class="isMediaNarrow && 'narrow'">
      <slot name="media" />
    </div>
    <CenteredTextBox v-bind="{ content }" class="textBox" />
  </Container>
</template>

<script>
import CenteredTextBox from '../blocks/CenteredTextBox.vue'

export default {
  components: {
    CenteredTextBox,
  },
  props: {
    content: Object,
  },
  computed: {
    ctaOptions() {
      const { backgroundColor } = this.content.presentationOptions
      return {
        form: 'button',
        colorClass: backgroundColor === 'dark-blue' ? '--bright-blue' : '--dark-blue',
      }
    },
    isMediaNarrow() {
      return this.content.presentationOptions?.width === 'narrow'
    },
  },
}
</script>

<style scoped>

.mediaChunk + .textBox {
  margin-top: var(--spacing-10);
}

@media (--screen-lg) {

  .mediaChunk + .textBox {
    margin-top: 0;
  }

  .CenteredContentSection {
    --heading-font-size: var(--font-size-9);
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .mediaChunk {
    margin-top: var(--spacing-10);
  }

  .mediaChunk.narrow {
    max-width: 1060px;
  }
}
</style>
