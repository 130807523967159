<template>
  <ColorContext class="FinePrint" :backgroundColor="backgroundColor">
    <Container v-if="content.body">
      <BlockContent :blocks="content.body" />
    </Container>
  </ColorContext>
</template>

<script>
import BlockContent from 'sanity-blocks-vue-component'
import ColorContext from '../elems/ColorContext.vue'

export default {
  components: {
    BlockContent,
    ColorContext,
  },
  props: {
    content: Object,
  },
  computed: {
    backgroundColor() {
      return this.content.presentationOptions?.backgroundColor;
    },
  },
}
</script>

<style scoped>
.FinePrint {

  background-color: var(--background-color);
  padding-top: var(--spacing-7);
  padding-bottom: var(--spacing-7);

  font-weight: normal;
  font-size: var(--font-size-1);
  font-style: italic;
  line-height: 1.7;
  color: var(--text-color);
}
</style>
