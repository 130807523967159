<template>
  <main>
    <HeroWithTextOnly v-if="content.hero" :breadcrumbs="breadcrumbs" :content="content.hero" />
    <component
      v-for="sectionContent in content.bodySections || []"
      :key="sectionContent._key"
      :is="bodySectionComponent(sectionContent._type)"
      :content="sectionContent"
    />
    <FooterCta v-if="content.footerCta" :content="content.footerCta" />
  </main>
</template>

<script>

import FooterCta from './headerFooterSections/FooterCta.vue'
import HeroWithTextOnly from './headerFooterSections/HeroWithTextOnly.vue'
import PlaceholderSection from './bodySections/PlaceholderSection.vue'

import OfficeContactDetails from './contactPageSections/OfficeContactDetails.vue'
import OfficeLocationGrid from './contactPageSections/OfficeLocationGrid.vue'

export default {
  components: {
    FooterCta,
    HeroWithTextOnly,
    OfficeContactDetails,
    OfficeLocationGrid,
    PlaceholderSection,
  },
  props: {
    breadcrumbs: Array,
    content: Object,
  },
  provide() {
    return {
      locale: () => this.content?.locale,
    }
  },
  methods: {
    bodySectionComponent(type) {
      switch (type) {
      case 'officeContactDetails':
        return 'OfficeContactDetails'
      case 'officeLocationGrid':
        return 'OfficeLocationGrid'
      default:
        return 'PlaceholderSection'
      }
    },
  },
  contentQuery: `
    *[_id == $id][0]{
      locale,
      hero,
      bodySections,
      footerCta->{ title, text, cta },
    }
  `,
}
</script>
