<template>
  <SanityImage class="ScaledImage" v-bind="{ asset, ...scaledDimensions }" />
</template>

<script>

// Extract the image dimensions from the image id.
// For example, if imageId is 'image-8907715eb86be773b8a2564885e96edd3bb08a89-2560x1440-png' then the
// dimensions will be { width: 2560, height: 1440 }.
function imageDimensions(imageId) {
  const matchData = imageId.match(/^image-[0-9a-f]{40}-(?<width>\d+)x(?<height>\d+)-\w+$/)
  if (matchData === null) {
    throw `Invalid Sanity image id '${imageId}'`
  }
  return matchData.groups;
}

export default {
  props: {
    asset: {
      type: Object,
      default: null,
    },
    diagonalLength: {
      type: Number,
      default: 100,
    },
  },
  computed: {
    scaledDimensions() {
      if (!this.asset) return { width: 0, height: 0 }
      const { width, height } = imageDimensions(this.asset._ref)
      const scale = this.diagonalLength / Math.hypot(width, height)
      return { width: scale * width, height: scale * height }
    },
  },
}
</script>
