<template>
  <Container tagName="section" class="ProductFeatures">
    <h2 v-if="content.heading" class="heading">{{ content.heading }}</h2>
    <div class="sections">
      <div
        v-for="item in content.features"
        :key="item._key"
        class="section"
      >
        <ProductFeature v-if="item._type == 'feature'" :content="item" />
        <ProductFeatureDocumentation v-if="item._type == 'documentation'" :content="item" />
        <ProductFeatureFineprint v-if="item._type == 'finePrint'" :content="item" />
        <ProductFeatureList v-if="item._type == 'operatingSystems'" :content="item" />
      </div>
    </div>
  </Container>
</template>

<script>
import ProductFeature from '../elems/ProductFeature.vue'
import ProductFeatureDocumentation from '../elems/ProductFeatureDocumentation.vue'
import ProductFeatureFineprint from '../elems/ProductFeatureFinePrint.vue'
import ProductFeatureList from '../elems/ProductFeatureList.vue'

export default {
  components: {
    ProductFeature,
    ProductFeatureDocumentation,
    ProductFeatureFineprint,
    ProductFeatureList,
  },
  props: {
    content: Object,
  },
}
</script>

<style scoped>
  .ProductFeatures {
    padding-top: var(--spacing-15);
    padding-bottom: var(--spacing-15);
    color: var(--text-color-dark);
  }
  .heading {
    color: var(--text-color-black);
    font-weight: 600;
    font-size: var(--font-size-8);
    line-height: 1.2;
    margin-bottom: var(--spacing-10);
  }
  .section {
    margin-top: var(--spacing-10);
    padding-top: var(--spacing-10);
  }
  .section + .section {
    border-top: thin solid var(--hairline-color);
  }
</style>
