<template>
  <div class="heroBlurb" :class="colorMode">
    <component v-if="content.label" :is="useH1 ? 'h1' : 'span'" class="label">{{ content.label }}</component>
    <component :is="!content.label && useH1 ? 'h1' : 'p'" class="heading">{{ content.heading }}</component>
    <p class="overview">{{ content.overview }}</p>
    <div v-if="content.cta" class="ctaWrapper">
      <CtaButton :class="buttonColor" class="ctaButton" v-bind="content.cta" />
      <CtaLink v-if="content.secondaryCta && content.secondaryCta.text" class="ctaLink" v-bind="content.secondaryCta" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      required: true,
    },
    colorMode: {
      type: String,
      default: 'dark',
    },
    useH1: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    buttonColor() {
      switch (this.colorMode) {
        case 'dark':
          return '--bright-blue'
        default:
          return '--dark-blue'
      }
    },
  },
}
</script>

<style scoped>
.heroBlurb.light {
  color: var(--text-color-dark);
}
.heroBlurb.light .heading {
  color: var(--text-color-black);
}
.heroBlurb.dark {
  color: var(--text-color-white);
}

.label {
  font-weight: 500;
  font-size: var(--font-size-2);
  line-height: 1;
  text-transform: uppercase;
}
.heading {
  font-weight: 600;
  font-size: var(--font-size-10);
  line-height: 1.2;
}
.overview {
  font-weight: normal;
  font-size: var(--font-size-4);
  line-height: 1.7;
}
.label + .heading {
  margin-top: var(--spacing-5);
}
.heading + .overview {
  margin-top: var(--spacing-7);
}
.overview + .ctaWrapper {
  margin-top: var(--spacing-7);
}
.ctaWrapper {
  display: flex;
  column-gap:var(--spacing-8);
  row-gap: var(--spacing-10);
  align-items: center;
  flex-wrap: wrap;
}
.ctaButton, .ctaLink {
  flex-shrink: 0;
}

@media (--screen-lg) {
  .heading {
    line-height: 1.15;
  }
}
</style>
