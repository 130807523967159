<template>
  <aside class="InPageForm">

    <Container>

      <div class="contents" v-if="localeStrings">

        <h2 class="title">{{ content.title }}</h2>

        <form class="form" @submit.prevent="submitForm">

          <div class="formFields">

            <label @change="companyCheckInvalid" @input="companyCheckValid" class="formField" :class="fieldStatus.company">
              <div class="fieldTitle">{{ localeStrings.company }}</div>
              <input v-model="fieldValues.company" class="fieldInput" v-bind="{ disabled }">
              <div class="fieldErrorMessage" :hidden="fieldStatus.company !== 'invalid'">{{ localeStrings.errorMessages.required }}</div>
            </label>

            <label @change="fullNameCheckInvalid" @input="fullNameCheckValid" class="formField" :class="fieldStatus.fullName">
              <div class="fieldTitle">{{ localeStrings.fullName }}</div>
              <input v-model="fieldValues.fullName" class="fieldInput" v-bind="{ disabled }">
              <div class="fieldErrorMessage" :hidden="fieldStatus.fullName !== 'invalid'">{{ localeStrings.errorMessages.required }}</div>
            </label>

            <label @change="emailCheckInvalid" @input="emailCheckValid" class="formField" :class="fieldStatus.email">
              <div class="fieldTitle">{{ localeStrings.email }}</div>
              <input v-model="fieldValues.email" class="fieldInput" v-bind="{ disabled }">
              <div class="fieldErrorMessage" :hidden="fieldStatus.email !== 'invalid'">{{ localeStrings.errorMessages.email }}</div>
            </label>

            <label @change="phoneCheckInvalid" @input="phoneCheckValid" class="formField" :class="fieldStatus.phone">
              <div class="fieldTitle">{{ localeStrings.phone }}</div>
              <input v-model="fieldValues.phone" class="fieldInput" v-bind="{ disabled }">
              <div class="fieldErrorMessage" :hidden="fieldStatus.phone !== 'invalid'">{{ localeStrings.errorMessages.phone }}</div>
            </label>

          </div>

          <div class="formFooter">
            <button class="button" v-bind="{ disabled }">{{ content.buttonText }}</button>
            <div class="statusMessage" :hidden="submissionStatus !== 'submitted'">
              {{ content.thankYouText }}
            </div>
            <div class="statusMessage" :hidden="submissionStatus !== 'failed'">
              {{ localeStrings.submissionErrorText }}
            </div>
          </div>

        </form>

      </div>

    </Container>

  </aside>

</template>

<script>
import strings from './strings.json'

function testEmail(value) {
  const regExp = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z0-9]{2,24}$/
  return regExp.test(value.trim())
}

function testPhone(value) {
  const regExp = /^[EXText.+#\-()\d\s]{7,25}$/
  return regExp.test(value.trim())
}

export default {
  name: 'InPageForm',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    whenSubmitted: {
      type: Function,
    },
  },
  inject: ['locale'],
  computed: {
    disabled() {
      return this.submissionStatus === 'submitting'
    },
    localeStrings() {
      return strings[this.locale()]
    },
  },
  data() {
    return {
      submissionStatus: 'unsubmitted',
      fieldStatus: {
        company: 'pending',
        fullName: 'pending',
        email: 'pending',
        phone: 'pending',
      },
      fieldValues: {
        company: '',
        fullName: '',
        email: '',
        phone: '',
      },
    }
  },
  methods: {
    companyCheckValid() { if (this.fieldValues.company) this.fieldStatus.company = 'valid' },
    companyCheckInvalid() { if (!this.fieldValues.company) this.fieldStatus.company = 'invalid' },
    fullNameCheckValid() { if (this.fieldValues.fullName) this.fieldStatus.fullName = 'valid' },
    fullNameCheckInvalid() { if (!this.fieldValues.fullName) this.fieldStatus.fullName = 'invalid' },
    emailCheckValid() { if (testEmail(this.fieldValues.email)) this.fieldStatus.email = 'valid' },
    emailCheckInvalid() { if (!testEmail(this.fieldValues.email)) this.fieldStatus.email = 'invalid' },
    phoneCheckValid() { if (testPhone(this.fieldValues.phone)) this.fieldStatus.phone = 'valid' },
    phoneCheckInvalid() { if (!testPhone(this.fieldValues.phone)) this.fieldStatus.phone = 'invalid' },
    allCheckInvalid() {
      this.companyCheckInvalid()
      this.fullNameCheckInvalid()
      this.emailCheckInvalid()
      this.phoneCheckInvalid()
    },
    submitForm() {
      const endpoint = strings[this.locale()].endpoint
      if (typeof window !== 'undefined') {
        this.allCheckInvalid()
        const valid = Object.values(this.fieldStatus).every((status) => status === 'valid')
        if (valid) {
          const implicitValues = {
            emailRecipient: this.content.recipientEmailAddress,
            emailSubject: this.content.emailSubject,
          }
          this.submissionStatus = 'submitting'
          window.fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify({ ...implicitValues, ...this.fieldValues }),
          })
            .then((response) => {
              if (response.ok) {
                this.submissionStatus = 'submitted'
                this.$pageAction('inPageFormConversionEvent', this.content)
                if (this.whenSubmitted) {
                  this.whenSubmitted()
                }
              }
              else {
                this.submissionStatus = 'failed'
              }
            })
            .catch(() => {
              this.submissionStatus = 'failed'
            })
        }
      }
    },
  },
}
</script>

<style scoped>

.InPageForm {
  border-top: thin solid var(--hairline-color);
  padding-top: var(--spacing-14);
  padding-bottom: var(--spacing-16);
}

.contents {
  max-width: 48rem;
  margin: 0 auto;
}

.title {
  font-weight: 600;
  font-size: var(--font-size-9);
  line-height: 1.2;
  color: var(--text-color-black);
  text-align: center;
}

.title + .form {
  margin-top: var(--spacing-12);
}

.formFields {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: var(--spacing-4);
}

.fieldTitle {
  font-weight: 400;
  font-size: var(--font-size-2);
  color: var(--text-color-black);
}

.fieldInput {
  display: block;
  width: 100%;
  border: thin solid #ddd;
  border-radius: 4px;
  padding: var(--spacing-2) var(--spacing-3);
}

.formField.invalid .fieldInput {
  border-color: #d00;
}

.fieldErrorMessage {
  font-weight: 400;
  font-size: var(--font-size-2);
  color: #d00;
}

.fieldTitle + .fieldInput {
  margin-top: 0.25rem;
}

.fieldInput + .fieldErrorMessage {
  margin-top: 0.25rem;
}

.button {

  border-radius: 4px;
  padding: var(--spacing-4) var(--spacing-7);
  background-color: var(--bg-color-dark-blue);

  font-weight: 400;
  font-size: var(--font-size-3);
  line-height: 1;
  color: var(--text-color-white);

  transition: all 0.15s;
}

.button:not(:disabled):hover {
  background-color: #0081d6;
}

.fieldInput:disabled, .button:disabled {
  opacity: 0.25;
}

.formFooter {
  display: flex;
  gap: var(--spacing-4);
  align-items: center;
}

.statusMessage {
  font-weight: 400;
  font-size: var(--font-size-2);
  line-height: 1.3;
  color: var(--text-color-black);
}

.formFields + .formFooter {
  margin-top: var(--spacing-7);
}

@media (--screen-lg) {

  .formFields {
    grid-template-columns: 1fr 1fr;
    column-gap: var(--spacing-6);
  }
}
</style>
