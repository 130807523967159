<template>
  <ColorContext :backgroundColor="backgroundColor">
    <Container
      tagName="section"
      class="ImageBreakoutSection"
    >
      <div
        class="imageBreakout"
        :class="isImageAlignedRight ? 'alignImageRight' : 'alignImageLeft'"
      >
        <div class="image" v-if="content.image">
          <SanityImage :asset="content.image.asset" />
        </div>
        <div class="textPane">
          <h2 class="heading">{{ content.heading }}</h2>
          <DefaultBlockContent v-if="content.body" :blocks="content.body" class="body" />
          <div v-if="content.cta" class="ctaWrapper">
            <CtaButton :class="buttonColor" v-bind="content.cta" />
          </div>
        </div>
      </div>
    </Container>
  </ColorContext>
</template>

<script>
import DefaultBlockContent from '../elems/DefaultBlockContent.vue'
import ColorContext from '../elems/ColorContext.vue'

export default {
  components: {
    DefaultBlockContent,
    ColorContext,
  },
  props: {
    content: Object,
  },
  computed: {
    backgroundColor() {
      return this.content.presentationOptions?.backgroundColor;
    },
    buttonColor() {
      switch (this.content.presentationOptions?.backgroundColor) {
        case 'dark-blue':
          return '--bright-blue'
        default:
          return '--dark-blue'
      }
    },
    isImageAlignedRight() {
      return this.content.presentationOptions?.imageAlign === 'right'
    },
  },
}
</script>

<style scoped>

.ImageBreakoutSection {
  padding-top: var(--spacing-15);
  padding-bottom: var(--spacing-15);
}

.textPane {
  color: var(--text-color);
  padding: var(--spacing-12);
  background-color: var(--background-color);
  border-radius: var(--border-radius);
}

.heading {
  color: var(--heading-color);
  font-size: var(--font-size-8);
  font-weight: 600;
  line-height: 1.2;
}

.heading + .body {
  margin-top: var(--spacing-7);
}

.body + .ctaWrapper {
  margin-top: var(--spacing-8);
}

.image + .textPane {
  margin-top: var(--spacing-10);
}

.image img {
  width: 100%;
  border-radius: var(--border-radius);
}

@media (--screen-lg) {

  .ImageBreakoutSection {
    background-color: inherit;
  }

  .imageBreakout {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    gap: 2rem;
    align-items: center;
  }

  .image {
    align-self: stretch;
  }

  .textPane {
    grid-column: span 4 / span 4;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .image + .textPane {
    margin-top: 0;
    grid-row: 1;
  }

  .alignImageLeft .textPane {
    grid-column: 4 / span 4;
  }

  .alignImageRight .textPane {
    grid-column: 1 / span 4;
  }

  .alignImageLeft .image {
    grid-column: 1 / span 3;
  }

  .alignImageRight .image {
    grid-column: 5 / span 3;
  }
}
</style>
