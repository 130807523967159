<template>
  <main>
    <HeroWithTextOnly v-if="content.hero" :breadcrumbs="breadcrumbs" :content="content.hero" />
    <Container v-if="content.leaders" tagName="section" class="LeadershipGrid">
      <LeaderCard v-for="leader in content.leaders" :key="leader._key" :leader="leader" />
    </Container>
    <Container v-if="content.cta" tagName="section" class="LeadershipCta">
      <CtaLink v-bind="content.cta" />
    </Container>
    <FooterCta v-if="content.footerCta" :content="content.footerCta" />
  </main>
</template>

<script>
import FooterCta from '../headerFooterSections/FooterCta.vue'
import HeroWithTextOnly from '../headerFooterSections/HeroWithTextOnly.vue'
import LeaderCard from './LeaderCard.vue'

export default {
  components: {
    FooterCta,
    HeroWithTextOnly,
    LeaderCard,
  },
  props: {
    breadcrumbs: Array,
    content: Object,
  },
  provide() {
    return {
      locale: () => this.content?.locale,
    }
  },
  contentQuery: `
    *[_id == $id][0]{
      locale,
      hero,
      introText,
      leaders,
      cta,
      footerCta->{ title, text, cta },
    }
  `,
}
</script>

<style scoped>
.LeadershipGrid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: var(--spacing-14) var(--spacing-14);
  padding-top: var(--spacing-15);
  padding-bottom: var(--spacing-15);
  color: var(--text-color-dark);
}

.LeadershipCta {
  padding-bottom: var(--spacing-15);
  color: var(--text-color-dark);
}

@media (--screen-lg) {
  .LeadershipGrid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .leaderModal .modalPane  {
    margin: var(--spacing-10) auto;
    border-radius: var(--border-radius);
  }
}
</style>
