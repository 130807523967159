<template>
  <ColorContext :backgroundColor="backgroundColor">
    <Container
      tagName="section"
      class="StandoutContentSection"
      :class="isInlineOffsetSection && 'inlineOffsetLayout'"
    >
      <div
        class="standoutSection"
        :class="[
          isCenteredSection ? 'centeredLayout' : 'inlineLayout',
          isImageAlignedRight ? 'alignImageRight' : 'alignImageLeft',
        ]"
      >
        <div class="image" v-if="content.image">
          <SanityImage :asset="content.image.asset" />
        </div>
        <div class="text">
          <h2 class="heading">{{ content.heading }}</h2>
          <DefaultBlockContent v-if="content.body" :blocks="content.body" class="body" />
          <div v-if="content.cta" class="ctaWrapper">
            <CtaButton :class="buttonColor" v-bind="content.cta" />
          </div>
        </div>
      </div>
    </Container>
  </ColorContext>
</template>

<script>
import DefaultBlockContent from '../elems/DefaultBlockContent.vue'
import ColorContext from '../elems/ColorContext.vue'

export default {
  components: {
    DefaultBlockContent,
    ColorContext,
  },
  props: {
    content: Object,
  },
  computed: {
    backgroundColor() {
      return this.content.presentationOptions?.backgroundColor;
    },
    buttonColor() {
      switch (this.content.presentationOptions?.backgroundColor) {
        case 'dark-blue':
          return '--bright-blue'
        default:
          return '--dark-blue'
      }
    },
    isCenteredSection() {
      return this.content.presentationOptions?.layout === 'centered'
    },
    isInlineOffsetSection() {
      return this.content.presentationOptions?.layout === 'inline-offset'
    },
    isImageAlignedRight() {
      return this.content.presentationOptions?.imageAlign === 'right'
    },
  },
}
</script>

<style scoped>

.StandoutContentSection {
  background-color: var(--background-color);
  padding-top: var(--spacing-15);
  padding-bottom: var(--spacing-15);
}

.standoutSection {
  border-radius: 0;
  color: var(--text-color);
}

.StandoutContentSection.inlineOffsetLayout {
  padding-top: var(--spacing-25);
}

.StandoutContentSection.inlineOffsetLayout .inlineLayout .image {
  margin-top: calc(-1 * var(--spacing-24));
}

.heading {
  color: var(--heading-color);
  font-size: var(--font-size-8);
  font-weight: 600;
  line-height: 1.2;
}

.heading + .body {
  margin-top: var(--spacing-7);
}

.body + .ctaWrapper {
  margin-top: var(--spacing-8);
}

.image + .text {
  margin-top: var(--spacing-10);
}

.image img {
  border-radius: var(--border-radius);
}

@media (--screen-lg) {

  .StandoutContentSection {
    background-color: inherit;
  }

  .StandoutContentSection.inlineOffsetLayout {
    padding-top: var(--spacing-15);
  }

  .standoutSection {
    padding-top: var(--spacing-13);
    padding-bottom: var(--spacing-13);
    background-color: var(--background-color);
    border-radius: var(--border-radius);
    column-gap: var(--spacing-4);
    display: grid;
    row-gap: var(--spacing-10);
  }

  .centeredLayout {
    padding-bottom: 0;
  }

  .centeredLayout .heading {
    font-size: var(--font-size-8);
  }

  .centeredLayout .text {
    grid-row: 1;
    text-align: center;
    margin: 0 auto var(--spacing-13);
    max-width: 850px;
  }

  .centeredLayout .image {
    grid-row: 2;
    margin: 0 auto;
    max-width: 1060px;
  }

  .centeredLayout .image img {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .centeredLayout .image + .text {
    margin-bottom: 0;
  }

  .inlineLayout {
    grid-template-columns: repeat(12, 1fr);
  }

  .inlineLayout .text {
    margin-top: 0;
    max-width: 640px;
  }

  .inlineLayout .image {
    max-width: 520px;
  }

  .inlineLayout .text,
  .inlineLayout .image {
    grid-row: 1;
    align-self: center;
  }

  .StandoutContentSection.inlineOffsetLayout .inlineLayout .image {
    margin-top: calc(-1 * var(--spacing-16));
  }

  .inlineLayout.alignImageLeft .text {
    grid-column: 7 / span 5;
  }

  .inlineLayout.alignImageRight .text {
    grid-column: 2 / span 5;
  }

  .inlineLayout.alignImageLeft .image {
    grid-column: 2 / span 4;
  }

  .inlineLayout.alignImageRight .image {
    grid-column: 8 / span 4;
  }
}
</style>
