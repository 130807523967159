<template>
  <Container v-if="content.endDate" tagName="section" class="CountdownTimerSection">
    <div class="CountdownTimer">
      <SanityImage v-if="content.icon" :asset="content.icon.asset" />
      <div class="text">
        <div>{{ workingDays }} {{ content.workingDays }}</div>
        <p v-if="content.description">{{ content.description }}</p>
      </div>
    </div>
  </Container>
</template>

<script>
import timezones from '../timezones.json'

export default {
  props: {
    content: Object,
  },
  data() {
    return {
      localeTimezone: timezones[this.locale],
    }
  },
  computed: {
    startDate() {
      return new Date(
        new Date().toLocaleString('en', { timeZone: this.localeTimezone })
      )
    },
    endDate() {
      return new Date(
        new Date(this.content.endDate).toLocaleString('en', { timeZone: this.localeTimezone })
      )
    },
    workingDays() {
      const dayInMillis = 1000 * 3600 * 24;
      const startDateUtc = this.startDate.getTime();
      const endDateUtc = this.endDate.getTime();
      const daysDiff = Math.floor((endDateUtc - startDateUtc) / dayInMillis) + 1;
      let count = 0;

      for (let i = 0; i < daysDiff; i++) {
        const currentDateUtc = startDateUtc + i * dayInMillis;
        const currentDay = new Date(currentDateUtc).getDay();
        if (currentDay !== 0 && currentDay !== 6) {
          count++;
        }
      }

      return count;
    },
  },
}
</script>

<style scoped>
.CountdownTimer {
  display: flex;
  gap: var(--spacing-9);
  align-items: flex-start;
  border-radius: var(--border-radius);
  color: var(--text-color-white);
  overflow: hidden;
  background-color: var(--bg-color-dark-blue);
  padding: var(--spacing-9);
}
.CountdownTimer img {
  flex-shrink: 0;
}
.text div {
  font-size: var(--font-size-5);
  font-weight: 600;
}
.text p {
  font-size: var(--font-size-3);
  margin-top: var(--spacing-1);
}
@media (--screen-lg) {
  .CountdownTimer {
    gap: var(--spacing-6);
    align-items: center;
  }
  .text p {
    margin-top: 0;
  }
}
</style>
