<template>
  <div class="LogoGrid" :style="{ '--num-columns': Math.min(logos.length, 5) }">
    <div v-for="item in logos" :key="item._key" class="logoFrame">
      <a v-if="item.url" :href="item.url" target="_blank"><SanityImage :asset="item.logo.asset" :alt="item.title" /></a>
      <SanityImage v-else :asset="item.logo.asset" :alt="item.title" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    logos: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style scoped>
.LogoGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-5);
}
.logoFrame {
  border: 1px solid #EEEEEE;
  border-radius: 5px;
  overflow: hidden;
}

@media (--screen-lg) {
  .LogoGrid {
    grid-template-columns: repeat(var(--num-columns), 1fr);
  }
  .logoFrame {
    border-radius: 10px;
  }
}
</style>
