<template>
  <SectionGroup v-if="content._type === 'sectionGroup'" :heading="content.heading">
    <component
      v-for="sectionContent in content.bodySections || []"
      :is="bodySectionComponent(sectionContent._type)"
      :key="sectionContent._key"
      :content="sectionContent"
    />
  </SectionGroup>
  <component v-else :is="bodySectionComponent(content._type)" :content="content" />
</template>

<script>

import SectionGroup from './SectionGroup.vue'

import AppPromoModule from './AppPromoModule.vue'
import BasicContentSection from './BasicContentSection/index.vue'
import BillboardSection from './BillboardSection.vue'
import CardCarousel from './CardCarousel/index.vue'
import CaseStudyCallout from './CaseStudyCallout.vue'
import CenteredIframe from './CenteredIframe.vue'
import CollapsibleFeatureListing from './CollapsibleFeatureListing/index.vue'
import ColorfulGrid from './ColorfulGrid.vue'
import FaqSection from './FaqSection.vue'
import FeatureGrid from './FeatureGrid/index.vue'
import FeatureOverview from './FeatureOverview.vue'
import FinePrint from './FinePrint.vue'
import IconStripModule from './IconStripModule.vue'
import ImageBreakoutSection from './ImageBreakoutSection.vue'
import InPageForm from './InPageForm/index.vue'
import LogoGridModule from './LogoGridModule.vue'
import LogoStripModule from './LogoStripModule.vue'
import MarketingHighlights from './MarketingHighlights.vue'
import MinorSections from './MinorSections.vue'
import PlaceholderSection from './PlaceholderSection.vue'
import ProductCards from './ProductCards/index.vue'
import PopupTrigger from './PopupTrigger/index.vue'
import ProductFeatureCards from './ProductFeatureCards/index.vue'
import ProductFeatures from './ProductFeatures.vue'
import QuoteCallout from './QuoteCallout.vue'
import ResourceDownloads from './ResourceDownloads/index.vue'
import StandoutContentSection from './StandoutContentSection.vue'
import TabbedImageContentSection from './TabbedImageContentSection/index.vue'
import TextContentSection from './TextContentSection.vue'

export default {
  components: {
    SectionGroup,
  },
  props: {
    content: Object,
  },
  methods: {
    bodySectionComponent(type) {
      switch (type) {
      case 'appPromoModule':
        return AppPromoModule
      case 'billboard':
        return BillboardSection
      case 'cardCarousel':
        return CardCarousel
      case 'caseStudyCallout':
        return CaseStudyCallout
      case 'centeredIframe':
        return CenteredIframe
      case 'collapsibleFeatureListing':
        return CollapsibleFeatureListing
      case 'colorfulGrid':
        return ColorfulGrid
      case 'faqSection':
        return FaqSection
      case 'featureGrid':
        return FeatureGrid
      case 'featureOverview':
        return FeatureOverview
      case 'finePrint':
        return FinePrint
      case 'iconStrip':
        return IconStripModule
      case 'imageBreakoutSection':
        return ImageBreakoutSection
      case 'inPageForm':
        return InPageForm
      case 'logoGrid':
        return LogoGridModule
      case 'logoStrip':
        return LogoStripModule
      case 'marketingHighlights':
        return MarketingHighlights
      case 'minorSections':
        return MinorSections
      case 'polymorphicSection':
        return BasicContentSection
      case 'popupTrigger':
        return PopupTrigger
      case 'productCards':
        return ProductCards
      case 'productFeatureCards':
        return ProductFeatureCards
      case 'productFeatures':
        return ProductFeatures
      case 'quoteCallout':
        return QuoteCallout
      case 'resourceDownloads':
        return ResourceDownloads
      case 'standoutContentSection':
        return StandoutContentSection
      case 'tabbedImageContentSection':
        return TabbedImageContentSection
      case 'textContentSection':
        return TextContentSection
      default:
        return PlaceholderSection
      }
    },
  },
}
</script>
