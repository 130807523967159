<template>
  <details class="FeatureDetails">
    <summary class="header" @click.prevent="selectThisFeature">
      <h3 class="heading">{{ content.heading }}</h3>
      <ExpandChevron class="faqQuestionIcon" />
    </summary>
    <div class="body">
      <DefaultBlockContent v-if="content.body" :blocks="content.body" />
      <div v-if="content.cta" class="ctaWrapper">
        <CtaLink v-bind="content.cta" />
      </div>
    </div>
  </details>

</template>

<script>
import DefaultBlockContent from '../../elems/DefaultBlockContent.vue'
import ExpandChevron from '../../icons/ExpandChevron.vue'

export default {
  components: {
    DefaultBlockContent,
    ExpandChevron,
  },
  props: {
    content: {
      type: Object,
      default: Object,
    },
    selectThisFeature: {
      type: Function,
      required: true,
    },
  },
}
</script>

<style scoped>

.FeatureDetails {
  border-bottom: 2px solid #939393;
  padding-top: var(--spacing-4);
}

.FeatureDetails[open] .header {
  color: var(--brand-color);
  border-bottom: 2px solid var(--brand-color);
}

.FeatureDetails[open] .faqQuestionIcon {
  color: var(--brand-color);
  transform: rotate(180deg);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-color-black);
  font-size: var(--font-size-4);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.1s;
  outline: none;
  padding-bottom: var(--spacing-4);
}

.heading {
  padding-right: var(--spacing-9);
  outline: none;
}

.body {
  padding-top: var(--spacing-6);
  padding-bottom: var(--spacing-8);
}

.ctaWrapper {
  margin-top: var(--spacing-8);
}
</style>
