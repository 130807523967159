<template>
  <div class="PopupTrigger">
    <component :is="popupComponent" v-bind="{ content, hidePopup, ctaClicked }" class="popup" :hidden="status !== 'showing'" />
  </div>
</template>

<script>
import PopupDefault from './Default.vue'
import PopupMultiCta from './MultiCta.vue'

const MILLIS_BEFORE_POPUP_APPEARS = 10e3;

export default {
  components: {
    PopupDefault,
    PopupMultiCta,
  },
  props: {
    content: Object,
  },
  data() {
    return {
      status: 'ready',
    };
  },
  computed: {
    popupComponent() {
      switch (this.content?.variant) {
      case 'multiCta':
        return PopupMultiCta
      case 'default':
      default:
        return PopupDefault
      }
    }
  },
  methods: {
    hidePopup() {
      this.status = 'dismissed';
      this.$pageAction('promoPopupDismissed', this.content);
    },
    ctaClicked() {
      this.$pageAction('promoPopupCtaClicked', this.content);
    }
  },
  mounted() {
    if (typeof window !== 'undefined') {
      const showPopupAfterTimeout = () => {
        if (this.status === 'ready') {
          this.status = 'showing';
          this.$pageAction('promoPopupRevealedAfterTimeout', this.content);
        }
      };
      const timeoutId = window.setTimeout(showPopupAfterTimeout, MILLIS_BEFORE_POPUP_APPEARS);
      function cancelTimedPopupReveal() {
        window.clearTimeout(timeoutId);
        window.removeEventListener('scroll', cancelTimedPopupReveal);
      }
      const maybeShowPopupAfterScrolling = () => {
        if (this.status === 'ready') {
          const { y } = this.$el.getBoundingClientRect();
          if (y < 0.25 * window.innerHeight) {
            this.status = 'showing';
            this.$pageAction('promoPopupRevealedAfterScrolling', this.content);
          }
        }
      };
      window.addEventListener('scroll', cancelTimedPopupReveal);
      window.addEventListener('scroll', maybeShowPopupAfterScrolling);
    }
  },
}
</script>

<style scoped>

@keyframes revealPopup {
  from {
    bottom: -500px;
  }
  to {
    bottom: 0;
  }
}

.popup {
  position: fixed;
  right: 2.5rem;
  bottom: 0;
  animation: revealPopup 0.5s;
  z-index: 30;
}

@media (max-width: 640px) {
  .popup {
    display: none;
  }
}
</style>
