<template>
  <Container class="AppPromoModule" tagName="aside">
    <div class="standout">
      <div class="graphic">
        <SanityImage v-if="content.image" :asset="content.image.asset" class="image" />
      </div>
      <div class="text">
        <span v-if="content.label" class="label">{{ content.label }}</span>
        <h3 class="heading">{{ content.heading }}</h3>
        <div v-if="content.features" class="features">
          <div class="feature"
            v-for="{ _key, icon, text } in content.features"
            :key="_key"
          >
            <div class="icon" v-if="icon">
              <SanityImage :asset="icon.asset" />
            </div>
            <BlockContent v-if="text" :blocks="text" />
          </div>
        </div>
        <div v-if="(content.cta && content.cta.text)" class="ctaWrapper">
          <CtaButton v-if="content.cta" v-bind="content.cta" icon="arrow" class="cta --bright-blue" />
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import BlockContent from 'sanity-blocks-vue-component'

export default {
  components: {
    BlockContent,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style scoped>

.AppPromoModule {
  background-color: var(--bg-color-white);
  padding-top: var(--spacing-15);
  padding-bottom: var(--spacing-15);
}

.standout {
  padding: var(--spacing-13) var(--spacing-10);
  background-color: var(--bg-color-dark-blue);
  /*
    We would just use the blurred bg image here (see large breakpoint below),
    except that there's an apparent bug on iOS v16 that prevents it from
    rendering correctly. Instead, we approximate it with radial gradients.
  */
  background-image:
    radial-gradient(ellipse 60vw 40vw at 30% 10%, #fff9, transparent),
    radial-gradient(circle 50vw at 80% 15%, #ffba614f, transparent),
    radial-gradient(circle 70vw at 35% 20%, #fbdb7d54, transparent);
  border-radius: var(--border-radius);
  overflow: hidden;
}

.graphic {
  padding: var(--spacing-6);
}

.image {
  width: 27rem;
  max-width: 100%;
}

.text {
  color: var(--text-color-white);
}

.label {
  font-weight: 500;
  font-size: var(--font-size-3);
  text-transform: uppercase;
}

.heading {
  font-weight: 600;
  font-size: var(--font-size-8);
  line-height: 1.3;
  max-width: 8em;
}

.features {
  margin-top: var(--spacing-9);
}

.feature >>> p {
  font-size: var(--font-size-3);
  line-height: 1.6;
  max-width: 21em;
}

.feature {
  margin-top: var(--spacing-8);
  display: grid;
  grid-row-gap: var(--spacing-6);
}

.ctaWrapper {
  margin-top: var(--spacing-8);
}

@media (--screen-sm) {

  .feature {
    grid-template-columns: 3rem 1fr;
    grid-column-gap: var(--spacing-6);
  }

  .feature .icon {
    padding-top: 0.5rem;
  }
}

@media (--screen-lg) {

  .standout {
    padding: var(--spacing-14);
    display: grid;
    grid-template-columns: minmax(0, 7fr) minmax(0, 5fr);
    column-gap: var(--spacing-12);
    background-image: none;
  }

  .graphic, .image, .text {
    position: relative;
  }

  .graphic::before {
    position: absolute;
    top: 4%;
    left: -2%;
    content: url("../../assets/images/background-blur.svg");
    filter: blur(45px);
    transform: scale(1.25);
  }
}
</style>
