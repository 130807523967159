<template>
  <div class="FeatureGridItem">
    <div class="header">
      <div class="icon" v-if="icon">
        <SanityImage :asset="icon.asset" />
      </div>
      <div class="headerText">
        <div v-if="label" class="label">{{ label }}</div>
        <h3 v-if="heading" class="heading">{{ heading }}</h3>
      </div>
    </div>
    <BodyChunk v-bind="{ body, cta }" class="body" />
  </div>
</template>

<script>
import BodyChunk from '../blocks/BodyChunk.vue'

export default {
  components: { BodyChunk },
  props: {
    icon: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    heading: {
      type: String,
      default: '',
    },
    body: {
      type: Array,
      default: Array,
    },
    cta: {
      type: Object,
      default: null,
    },
  }
}
</script>

<style scoped>

.FeatureGridItem {
  padding-right: 10%;
}

.label {
  font-weight: 600;
  font-size: var(--font-size-1);
  line-height: 1;
  text-transform: uppercase;
  color: var(--brand-color);
}

.heading {
  color: var(--heading-color);
  font-weight: 600;
  font-size: var(--font-size-4);
  line-height: 1.2;
}

.icon >>> img {
  max-height: 90px;
}

.label + .heading {
  margin-top: var(--spacing-3);
}

.header + .body {
  margin-top: var(--spacing-5);
}

.header {
  display: flex;
  align-items: center;
  column-gap: var(--spacing-3);
}

.icon {
  min-width: 60px;
  display: flex;
  justify-content: center;
}

@media (--screen-lg) {

  .header, .icon {
    display: block;
  }

  .icon + .headerText {
    margin-top: var(--spacing-8);
  }
}
</style>
