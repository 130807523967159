<template>
  <ColorContext :backgroundColor="backgroundColor">
    <Container tagName="section" class="FeatureGrid" :style="cssVariables">
      <CenteredTextBox v-if="hasCenteredHeading"
        v-bind="{ content }"
        class="head"
      />
      <div class="gridItems">
        <FeatureGridInlineHead v-if="hasInlineHeading"
          v-bind="{ content }"
          class="item"
        />
        <FeatureGridItem v-for="feature in content.features"
          :key="feature._key"
          v-bind="feature"
          class="item"
        />
      </div>
    </Container>
  </ColorContext>
</template>

<script>
import BodyChunk from '../blocks/BodyChunk.vue'
import CenteredTextBox from '../blocks/CenteredTextBox.vue'
import ColorContext from '../../elems/ColorContext.vue'
import FeatureGridInlineHead from './FeatureGridInlineHead.vue'
import FeatureGridItem from './FeatureGridItem.vue'

export default {
  components: {
    ColorContext,
    CenteredTextBox,
    BodyChunk,
    FeatureGridInlineHead,
    FeatureGridItem,
  },
  props: {
    content: {
      type: Object,
      default: Object,
    },
  },
  computed: {
    backgroundColor() {
      return this.content.presentationOptions?.backgroundColor
    },
    cssVariables() {
      const featuresCount = this.content.features?.length || 0
      const itemsCount = featuresCount + (this.hasInlineHeading ? 1 : 0)
      return {
        '--grid-template-columns': Math.min(itemsCount, 3),
      }
    },
    hasCenteredHeading() {
      return this.content.presentationOptions?.heading === 'centered'
    },
    hasInlineHeading() {
      return this.content.presentationOptions?.heading === 'inline'
    },
  },
}
</script>

<style scoped>

.FeatureGrid {
  background-color: var(--background-color);
  color: var(--text-color);
  padding-bottom: var(--spacing-15);
  padding-top: var(--spacing-15);
}

.head + .gridItems {
  margin-top: var(--spacing-14);
}

.item + .item {
  margin-top: var(--spacing-12);
}

@media (--screen-lg) {

  .item + .item {
    margin-top: 0;
  }

  .gridItems {
    display: grid;
    grid-template-columns: repeat(var(--grid-template-columns), minmax(0, 1fr));
    gap: var(--spacing-14) var(--spacing-5);
  }
}
</style>
