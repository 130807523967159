<template>
  <div class="FeatureGridInlineHead">
    <h2 v-if="content.label" class="label">{{ content.label }}</h2>
    <component v-if="content.heading" :is="content.label ? 'h3' : 'h2'" class="heading">
      <span v-for="line in content.heading.split('//')" :key="line" class="line">
        {{ line }}
      </span>
    </component>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: Object,
    },
  }
}
</script>

<style scoped>

.label {
  font-weight: 600;
  font-size: var(--font-size-1);
  line-height: 1;
  text-transform: uppercase;
  color: var(--brand-color);
}

.heading {
  color: var(--heading-color);
  font-weight: 600;
  font-size: var(--font-size-7);
  line-height: 1.2;
}

.heading .line {
  display: block;
}

.label + .heading {
  margin-top: var(--spacing-3);
}
</style>
