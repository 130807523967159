<template>
  <ColorContext
    v-bind="{ backgroundColor }"
    class="FeatureOverview"
    :class="{ hasFeatures, hasLogoStrip }"
  >
    <Container tagName="section">
      <CenteredTextBox class="text" v-bind="{ content }" />
      <div v-if="content.image || hasFeatures" class="featuresWrapper">
        <div v-if="content.image" class="image">
          <SanityImage :asset="content.image.asset" />
        </div>
        <div v-if="hasFeatures" class="features">
          <div class="feature"
            v-for="{ _key, icon, heading, body } in content.features"
            :key="_key"
          >
            <div class="icon" v-if="icon">
              <SanityImage :asset="icon.asset" />
            </div>
            <h3 class="subheading" v-if="heading">{{ heading }}</h3>
            <DefaultBlockContent v-if="body" :blocks="body" class="body" />
          </div>
        </div>
      </div>
      <ScrollableLogoStrip v-if="hasLogoStrip" :content="content.logoStrip" />
    </Container>
  </ColorContext>
</template>

<script>
import CenteredTextBox from './blocks/CenteredTextBox.vue'
import DefaultBlockContent from '../elems/DefaultBlockContent.vue'
import ColorContext from '../elems/ColorContext.vue'
import ScrollableLogoStrip from '../elems/ScrollableLogoStrip.vue'

export default {
  components: {
    CenteredTextBox,
    DefaultBlockContent,
    ColorContext,
    ScrollableLogoStrip,
  },
  props: {
    content: Object,
  },
  computed: {
    backgroundColor() {
      return this.content.presentationOptions?.backgroundColor;
    },
    hasFeatures() {
      return this.content.presentationOptions?.hasFeatures && this.content.features
    },
    hasLogoStrip() {
      return (
        this.content.presentationOptions?.hasLogoStrip &&
        this.content.logoStrip.logos.length >= 3
      )
    },
  },
}
</script>

<style scoped>

.FeatureOverview {
  background-color: var(--background-color);
  color: var(--text-color);
  padding-bottom: var(--spacing-15);
  padding-top: var(--spacing-15);
}

.FeatureOverview.hasLogoStrip {
  padding-bottom: var(--spacing-13);
}

.FeatureOverview.hasLogoStrip .featuresWrapper {
  margin-bottom: var(--spacing-13);
}

.text + .featuresWrapper {
  margin-top: var(--spacing-14);
}

.image img {
  width: 100%;
  border-radius: var(--border-radius);
}

.feature {
  padding-top: var(--spacing-14);
  display: grid;
  grid-template-columns: 5rem 1fr;
}

.feature .icon {
  grid-row: 1;
  grid-column: 1;
}

.feature .subheading {
  grid-row: 1;
  grid-column: 2;
  color: var(--heading-color);
  font-weight: 600;
  font-size: var(--font-size-3);
  line-height: 1.2;
}

.feature .body {
  grid-row: 2;
  grid-column: 1 / span 2;
}

.feature .icon + .subheading {
  margin-top: var(--spacing-4);
}

.feature .subheading + .body {
  margin-top: var(--spacing-4);
}

@media (--screen-lg) {
  .heading {
    font-size: var(--font-size-9);
  }
  .FeatureOverview.hasFeatures .featuresWrapper {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: var(--spacing-14);
  }
  .image {
    grid-column: span 2 / span 2;
  }
  .image + .features {
    margin-top: var(--font-size-4);
  }
  .features {
    grid-column: span 3 / span 3;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: var(--spacing-16);
    row-gap: var(--spacing-8);
  }
  .feature {
    padding-top: 0;
    padding-right: var(--spacing-4);
    display: block;
  }
}
</style>
