<template>

  <Container tagName="header" class="HeroWithInlineMedia">

    <div class="heroContent">
      <div class="text">
        <h1 class="heading">{{ content.heading }}</h1>
        <p class="overview">{{ content.overview }}</p>
        <div v-if="content.cta" class="ctaWrapper">
          <CtaButton class="ctaButton --dark-blue" v-bind="content.cta" />
          <a v-if="content.secondaryCta" :href="content.secondaryCta.action" class="ctaLink" target="_blank">
            {{ content.secondaryCta.text }}
            <span class="icon" v-html="arrowRightSvg" />
          </a>
        </div>
      </div>
      <AnyMedia class="mediaChunk" :media="content.media" />
    </div>

  </Container>

</template>

<script>
import arrowRightSvg from '!raw-loader!@/assets/images/link-arrow-right.svg'
import AnyMedia from '../elems/AnyMedia.vue'

export default {
  components: {
    AnyMedia,
  },
  props: {
    content: Object,
  },
  data() {
    return {
      arrowRightSvg
    }
  },
}
</script>

<style scoped>

.HeroWithInlineMedia {
  padding-top: var(--spacing-15);
  padding-bottom: var(--spacing-15);
  background-color: var(--bg-color-white);
}

.text {
  color: var(--text-color-dark);
}
.text + .mediaChunk {
  margin-top: var(--spacing-13);
}
.heading {
  color: var(--text-color-black);
  font-weight: 600;
  font-size: var(--font-size-10);
  line-height: 1.2;
}
.overview {
  font-weight: normal;
  font-size: var(--font-size-4);
  line-height: 1.7;
}
.label + .heading {
  margin-top: var(--spacing-5);
}
.heading + .overview {
  margin-top: var(--spacing-7);
}
.overview + .ctaWrapper {
  margin-top: var(--spacing-7);
}
.ctaWrapper {
  display: flex;
  column-gap:var(--spacing-8);
  row-gap: var(--spacing-10);
  align-items: center;
  flex-wrap: wrap;
}
.ctaButton, .ctaLink {
  flex-shrink: 0;
}
.ctaLink {
  display: inline-block;
  font-weight: 400;
  font-size: var(--font-size-3);
  line-height: 1.3;
  color: var(--brand-color);
  transition: color 0.15s;
}

.ctaLink:hover {
  color: #0074C2;
}

.ctaLink:focus {
  color: #08192E;
}

.ctaLink[disabled] {
  color: var(--text-color-light);
}

.icon {
  display: inline-block;
  margin-left: 0.33em;
  position: relative;
  left: 0;
  transition: left 0.15s;
}

.ctaLink:hover .icon {
  left: 3px;
}

.mediaChunk >>> img,
.mediaChunk >>> video {
  border-radius: var(--border-radius);
}

@media (--screen-lg) {
  .HeroWithInlineMedia .heroContent {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 1rem;
    align-items: center;
  }

  .HeroWithInlineMedia .heroContent .text,
  .HeroWithInlineMedia .heroContent .mediaChunk {
    grid-row: 1;
    align-self: center;
  }
  .HeroWithInlineMedia .heroContent .text {
    grid-column: 1 / span 5;
  }
  .HeroWithInlineMedia .heroContent .mediaChunk {
    grid-column: 7 / span 6;
  }

  .text + .mediaChunk {
    margin-top: 0;
  }
  .heading {
    line-height: 1.15;
  }
}
</style>
