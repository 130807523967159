<template>
  <ColorContext :backgroundColor="backgroundColor">
    <Container
      tagName="section"
      class="CardCarousel"
    >
      <CenteredTextBox v-bind="{ content }" />
      <div v-if="content.cards" class="cardsLineup">
        <Card
          v-for="card in content.cards"
          :key="card._key"
          :content="card"
          class="card"
        />
      </div>
    </Container>
  </ColorContext>
</template>

<script>
import Card from './Card.vue'
import CenteredTextBox from '../blocks/CenteredTextBox.vue'
import ColorContext from '../../elems/ColorContext.vue'

export default {
  name: 'CardCarousel',
  components: {
    Card,
    CenteredTextBox,
    ColorContext,
  },
  props: {
    content: Object,
  },
  computed: {
    backgroundColor() {
      return this.content.presentationOptions?.backgroundColor;
    },
  },
}
</script>

<style scoped>

.CardCarousel {
  background-color: var(--background-color);
  padding-top: var(--spacing-15);
  padding-bottom: var(--spacing-15);
}

.cardsLineup {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--spacing-10);
  margin-top: var(--spacing-12);
}

.card {
  flex-basis: 100%;
}

@media (--screen-lg) {
  .card {
    flex-basis: 24.75rem;
  }
}
</style>
