<template>
  <div class="ProductFeatureCard">
    <div v-if="content.image" class="image">
      <ScaledImage :asset="content.image.asset" :diagonalLength="300" />
    </div>
    <h3 v-if="content.heading" class="heading">
      {{ content.heading }}
    </h3>
    <div
      v-for="feature in content.features"
      :key="feature._key"
      class="productFeature"
    >
      <SanityImage v-if="feature.icon" class="featureIcon" :asset="feature.icon.asset" />
      <div class="featureValue">{{ feature.featureValue }}</div>
      <div class="featureName">{{ feature.featureName }}</div>
    </div>
    <CtaLink v-if="content.cta" class="productCta" v-bind="content.cta" />
  </div>
</template>

<script>
import ColorContext from '../../elems/ColorContext.vue'
import DefaultBlockContent from '../../elems/DefaultBlockContent.vue'
import ScaledImage from '../../elems/ScaledImage.vue'

export default {
  components: {
    ColorContext,
    DefaultBlockContent,
    ScaledImage,
  },
  props: {
    content: {
      type: Object,
      default: Object,
    },
  },
}
</script>

<style scoped>

.ProductFeatureCard {
  display: flex;
  flex-direction: column;
  background-color: var(--bg-color-white);
  border-radius: var(--border-radius);
  color: var(--text-color-dark);
  padding: var(--spacing-9);
  text-align: center;
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--spacing-8);
}

.heading {
  color: var(--text-color-black);
  font-size: var(--font-size-3);
  font-weight: 600;
  line-height: 1.2;
}

.featureIcon {
  margin: var(--spacing-9) auto 0;
}
.featureValue {
  color: var(--text-color-black);
  font-size: var(--font-size-8);
  font-weight: 600;
  line-height: 1.2;
  margin-top: var(--spacing-8);
}
.featureIcon + .featureValue {
  margin-top: var(--spacing-4);
}
.featureName {
  color: var(--text-color-dark);
  font-weight: normal;
  font-size: var(--font-size-3);
  line-height: 1.7;
  margin-top: var(--spacing-3);
}

.productCta {
  margin-top: var(--spacing-8);
}

</style>
