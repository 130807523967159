// This file defines what happens when we launch this repo's preview server (`yarn serve`),
// and doesn't affect the published package in any way.

import Vue from 'vue'
import VueRouter from 'vue-router'

import App from './App.vue'
import makeRoutes from './lib/make-routes.js'
import config from './plugins/config.js'
import pageAction from './plugins/page-action.js'
import bodyScrollLock from './plugins/body-scroll-lock.js'
import sharedInseegoComponents from './plugins/shared-inseego-components.js'

import ContactPage from './components/ContactPage.vue'
import Homepage from './components/Homepage/index.vue'
import LeadershipPage from './components/LeadershipPage/index.vue'
import LegalDocumentPage from './components/LegalDocumentPage/index.vue'
import PolymorphicPage from './components/PolymorphicPage.vue'
import ProductPage from './components/ProductPage.vue'
import SupportInfoPage from './components/SupportInfoPage/index.vue'
import Upgrade3gTo4gPage from './components/Upgrade3gTo4gPage/index.vue'
import WhitepaperLandingPage from './components/WhitepaperLandingPage/index.vue'

const sanityConfig = {
  projectId: 'trgu17ld',
  dataset: 'development',
}

const samplePages = [
  {
    path: '/contact-page',
    id: '609c7dd5-e96a-4f91-8aa7-a97a86280fa0',
    component: ContactPage,
  },
  {
    path: '/homepage',
    id: 'by97RIoLn17QgTgW8lvuhs',
    component: Homepage,
  },
  {
    path: '/leadership-page',
    id: 'ea1c5f01-9d6b-4302-b5cf-88f26f7ad049',
    component: LeadershipPage,
  },
  {
    path: '/legal-document-page',
    id: 'sample-legal-document-page',
    component: LegalDocumentPage,
  },
  {
    path: '/polymorphic-landing-page',
    id: '5694406c-24fe-4f20-acb7-3b0436c9f863',
    component: PolymorphicPage,
  },
  {
    path: '/polymorphic-landing-page-alt',
    id: 'cb9d71a0-531b-4f9f-ae36-0deac42e6655',
    component: PolymorphicPage,
  },
  {
    path: '/polymorphic-page',
    id: 'c1008d03-4ae5-43ed-8489-06d52c5908cf',
    component: PolymorphicPage,
    isDefault: true,
  },
  {
    path: '/product-page',
    id: '7616fc59-60ff-4bb2-a978-ff96ceed385d',
    component: ProductPage,
  },
  {
    path: '/product-category-page',
    id: 'c84969fa-44c9-4622-a1a6-635c0d832fff',
    component: PolymorphicPage,
  },
  {
    path: '/support-info-page',
    id: '2d4a784d-f21c-4e24-81ff-31f91e566419',
    component: SupportInfoPage,
  },
  {
    path: '/upgrade-3g-to-4g-page',
    id: 'c7791f4b-a85f-4c74-84c3-e40e7f3fac54',
    component: Upgrade3gTo4gPage,
  },
  {
    path: '/whitepaper-landing-page',
    id: 'c15a0389-e95c-41b1-8671-f1221ee8162e',
    component: WhitepaperLandingPage,
  },
]

makeRoutes(sanityConfig, samplePages).then(function (routes) {

  const router = new VueRouter({ routes })

  // App setup
  Vue.use(VueRouter)
  Vue.use(config, { sanity: sanityConfig })
  Vue.config.productionTip = false
  Vue.use(pageAction)
  Vue.use(bodyScrollLock)
  Vue.use(sharedInseegoComponents)

  new Vue({
    render: (h) => h(App),
    router,
  }).$mount('#app')
})
