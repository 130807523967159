<template>
  <aside class="ImagePromoBanner">
    <Container class="container">
      <SanityImage v-if="content.mobileImage"
        :asset="content.mobileImage.asset"
        class="mobileImage"
      />
      <SanityImage v-if="content.desktopImageMain"
        :asset="content.desktopImageMain.asset"
        class="desktopImageMain"
      />
      <div class="text">
        <SanityImage v-if="content.desktopImageHeading"
          :asset="content.desktopImageHeading.asset"
          :alt="content.title"
          class="desktopImageHeading"
        />
        <CtaLink v-if="content.cta" v-bind="content.cta" class="cta" />
      </div>
    </Container>
  </aside>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: Object,
    },
  },
}
</script>

<style scoped>

.ImagePromoBanner {
  padding-top: var(--spacing-11);
  padding-bottom: var(--spacing-15);
  background-color: var(--bg-color-pale-blue);
}

.desktopImageMain, .desktopImageHeading {
  display: none;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  text-align: center;
}

.cta {
  margin-top: var(--spacing-7);
}

@media (--screen-lg) {

  .ImagePromoBanner {
    padding-top: var(--spacing-8);
    padding-bottom: var(--spacing-8);
  }

  .mobileImage {
    display: none;
  }

  .desktopImageMain, .desktopImageHeading {
    display: block;
  }

  .desktopImageMain {
    width: 300px;
    flex-grow: 0;
  }

  .container {
    flex-direction: row;
    column-gap: var(--spacing-8);
  }

  .text {
    flex-grow: 1;
    text-align: left;
  }

  .cta {
    margin-top: var(--spacing-5);
  }
}
</style>
