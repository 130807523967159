<template>
  <Container tagName="section" class="InlineContentSection">
    <div class="mediaChunk" :class="isMediaAlignedRight ? 'toRight' : 'toLeft'">
      <slot name="media" />
    </div>
    <div class="textBox" :class="isMediaAlignedRight ? 'toLeft' : 'toRight'">
      <HeadingChunk :heading="content.heading" :label="content.label" class="headingChunk" />
      <BodyChunk :body="content.body" :cta="content.cta" :ctas="content.ctas" class="bodyChunk" />
    </div>
  </Container>
</template>

<script>
import BodyChunk from '../blocks/BodyChunk.vue'
import HeadingChunk from '../blocks/HeadingChunk.vue'

export default {
  components: {
    BodyChunk,
    HeadingChunk,
  },
  props: {
    content: Object,
  },
  computed: {
    isMediaAlignedRight() {
      return this.content.presentationOptions?.imageAlign === 'right'
    },
  },
}
</script>

<style scoped>

.mediaChunk + .textBox {
  margin-top: var(--spacing-10);
}

.headingChunk + .bodyChunk {
  margin-top: var(--spacing-7);
}

@media (--screen-lg) {

  .mediaChunk + .textBox {
    margin-top: 0;
  }

  .InlineContentSection {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: var(--spacing-6);
  }

  .textBox .bodyChunk {
    max-width: 36rem;
  }

  .mediaChunk {
    max-width: 520px;
  }

  .textBox, .mediaChunk {
    grid-row: 1;
    align-self: center;
  }

  .textBox.toLeft {
    grid-column: 1;
  }

  .textBox.toRight {
    grid-column: 2;
  }

  .mediaChunk.toLeft {
    grid-column: 1;
    justify-self: left;
  }

  .mediaChunk.toRight {
    grid-column: 2;
    justify-self: right;
  }
}
</style>
