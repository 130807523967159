<template>
  <div class="LogoStrip">
    <div v-if="content.caption" class="logoColumn caption">{{ content.caption }}</div>
    <div
      v-for="{ _key, logo, title } in content.logos"
      :key="_key"
      class="logoColumn"
    >
      <ScaledImage v-if="logo" :asset="logo.asset" :alt="title" :diagonalLength="120" />
    </div>
  </div>
</template>

<script>
import ScaledImage from './ScaledImage.vue'

export default {
  components: {
    ScaledImage,
  },
  props: {
    content: Object,
  },
}
</script>

<style scoped>

.LogoStrip {
  padding-top: var(--spacing-6);
  padding-bottom: var(--spacing-6);
  display: flex;
  flex-flow: row;
  align-items: center;
}

.logoColumn {
  flex-shrink: 0;
}

.caption {
  font-weight: 400;
  font-size: var(--font-size-2);
  line-height: 1.3;
  color: #9C9C9C;
  max-width: 10rem;
}
</style>
