<template>
  <Container tagName="section" class="MarketingHighlights">
    <h2 class="heading">{{ content.heading }}</h2>
    <LogoGrid :logos="content.logos" class="logoGrid" />
    <Billboard v-if="content.billboard && content.billboard.title" :content="content.billboard" class="billboard" />
    <CtaBlock :text="content.lowerText" :cta="content.cta" class="ctaBlock" />
  </Container>
</template>

<script>
import Billboard from '../elems/Billboard.vue'
import CtaBlock from '../elems/CtaBlock.vue'
import LogoGrid from '../elems/LogoGrid.vue'

export default {
  components: {
    Billboard,
    CtaBlock,
    LogoGrid,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style scoped>
.MarketingHighlights {
  background-color: var(--bg-color-pale-blue);
  padding-top: var(--spacing-15);
  padding-bottom: var(--spacing-15);
}
.heading {
  margin: auto;
  max-width: 22em;
  font-weight: 600;
  font-size: var(--font-size-8);
  line-height: 1.3;
  color: var(--text-color-black);
  text-align: center;
}
.logoGrid {
  max-width: 65rem;
  margin-left: auto;
  margin-right: auto;
}
.ctaBlock {
  max-width: 53rem;
  margin-left: auto;
  margin-right: auto;
}
.heading + .logoGrid {
  margin-top: var(--spacing-8);
}
.logoGrid + .billboard {
  margin-top: var(--spacing-15);
}
.ctaBlock {
  margin-top: var(--spacing-14);
}
</style>
