<template>
  <Container class="PageNav" :class="{ isSticking }">
    <HorizontalScroller>
      <nav class="navTrack">
        <a
          v-for="{ _key, title, targetId } in entries"
          :key="_key"
          :href="`#${targetId}`"
          class="navEntry"
        >
          {{ title }}
        </a>
      </nav>
    </HorizontalScroller>
    <SmallCtaButton v-if="content && content.cta" class="ctaButton --outline" v-bind="content.cta" />
  </Container>
</template>

<script>
import HorizontalScroller from '../elems/HorizontalScroller.vue'

export default {
  components: {
    HorizontalScroller,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    entries: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isSticking: false,
      scrollEventListener: null,
    }
  },
  mounted() {
    this.scrollEventListener = () => {
      if (this.$el) {
        const { top } = this.$el.getBoundingClientRect()
        this.isSticking = (top <= 0)
      }
    }
    window.addEventListener('scroll', this.scrollEventListener)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.scrollEventListener)
  },
}
</script>

<style scoped>
  .PageNav {
    padding-top: var(--spacing-3);
    padding-right: 0 !important;
    padding-bottom: var(--spacing-3);

    border-top: thin solid var(--hairline-color);
    border-bottom: thin solid var(--hairline-color);

    background-color: white;
    color: var(--text-color-black);

    font-weight: normal;
    font-size: var(--font-size-2);

    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: var(--spacing-11);

    position: sticky;
    top: 0;
    z-index: 10;
  }

  .navTrack {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    padding-top: var(--spacing-3);
    padding-bottom: var(--spacing-3);
  }

  .navEntry {
    flex: 0 0;
    transition: color 0.15s;
  }

  .navEntry:hover {
    color: var(--brand-color);
  }

  .navEntry:last-child {
    padding-right: var(--gutter-width);
  }

  .navEntry + .navEntry {
    margin-left: 2.5rem;
  }

  .PageNav .ctaButton {
    display: none;
  }

  @media (--screen-lg) {
    .PageNav {
      padding-right: var(--gutter-width) !important;
    }
    .PageNav.isSticking .ctaButton {
      display: flex;
      flex-shrink: 0;
      min-width: 0;
    }

    .navEntry:last-child {
      padding-right: 0;
    }

    .navEntry + .navEntry {
      margin-left: 3rem;
    }
  }
</style>
