<template>
  <Container tagName="section" class="SupportFooterSection">
    <div class="text">
      <h2 class="heading">{{ content.heading }}</h2>
      <div class="body">
        <DefaultBlockContent :blocks="content.body" />
      </div>
    </div>
    <SanityImage :asset="content.image.asset" class="image" />
  </Container>
</template>

<script>
import DefaultBlockContent from '../elems/DefaultBlockContent.vue'

export default {
  components: {
    DefaultBlockContent
  },
  props: {
    content: {
      type: Object,
      default: Object,
    },
  },
}
</script>

<style scoped>

.SupportFooterSection {
  padding-top: var(--spacing-14);
  padding-bottom: var(--spacing-14);
  background-color: var(--bg-color-pale-gray);
}

.heading {
  font-weight: 600;
  font-size: var(--font-size-8);
  line-height: 1.3;
  color: var(--text-color-black);
}

.body {
  font-weight: 400;
  font-size: var(--font-size-3);
  line-height: 1.7;
  color: var(--text-color-dark);
}

.body >>> p + p {
  margin-top: var(--spacing-5);
}

.heading + .body {
  margin-top: var(--spacing-8);
}

.text + .image {
  margin-top: var(--spacing-12);
}

@media (--screen-lg) {

  .SupportFooterSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: var(--spacing-5);
  }

  .text + .image {
    margin-top: 0;
  }

  .text {
    max-width: 38rem;
  }

  .image {
    width: 40%;
  }
}
</style>
