<template>
  <Container tagName="section" class="CaseStudyCallout" :style="{ backgroundColor }">
    <div class="CaseStudy">
      <SanityImage v-if="content.image" :asset="content.image.asset" class="image" />
      <div class="textPane">
        <span v-if="content.label" class="label">{{ content.label }}</span>
        <h3 class="heading">{{ content.heading }}</h3>
        <div v-if="content.summary" class="summary">{{ content.summary }}</div>
        <div v-if="(content.cta && content.cta.text) || content.logo" class="ctaLogoWrapper">
          <CtaButton v-if="content.cta" v-bind="content.cta" icon="arrow" class="cta" :class="ctaColorClass" />
          <ScaledImage v-if="content.logo" :asset="content.logo.asset" :alt="content.heading" :diagonalLength="175" class="logo" />
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import ColorContext from '../elems/ColorContext.vue'
import DefaultBlockContent from '../elems/DefaultBlockContent.vue'
import ScaledImage from '../elems/ScaledImage.vue'

export default {
  components: {
    ColorContext,
    DefaultBlockContent,
    ScaledImage,
  },
  props: {
    content: Object,
  },
  computed: {
    backgroundColor() {
      switch (this.content.presentationOptions?.backgroundColor) {
        case 'pale-blue':
          return 'var(--bg-color-pale-blue)'
        default:
          return 'var(--bg-color-brand-blue)'
      }
    },
    ctaColorClass() {
      return this.content.presentationOptions?.backgroundColor === 'pale-blue' ? '--bright-blue' : '--dark-blue';
    },
  },
}
</script>

<style scoped>
.CaseStudyCallout {
  color: var(--text-color-dark);
  padding-bottom: var(--spacing-15);
  padding-top: var(--spacing-15);
}
.CaseStudy {
  border-radius: var(--border-radius);
  overflow: hidden;
}
.textPane {
  padding: var(--spacing-10);
  background-color: var(--bg-color-white);
}
.label {
  font-weight: 500;
  font-size: var(--font-size-3);
  line-height: 1;
  text-transform: uppercase;
  color: #08192E;
}
.heading {
  color: #08192E;
  font-size: var(--font-size-5);
  font-weight: 600;
  line-height: 1.2;
}
.label + .heading {
  margin-top: var(--spacing-6);
}
.heading + .summary {
  margin-top: var(--spacing-7);
}
.summary + .ctaLogoWrapper {
  margin-top: var(--spacing-8);
}
.summary, .logo {
  display: none;
}

@media (--screen-lg) {
  .CaseStudy {
    display: flex;
    align-items: flex-start;
  }
  .image {
    width: 37%;
    flex-basis: 37%;
  }
  .textPane {
    align-self: stretch;
    padding: var(--spacing-10);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .image + .textPane {
    width: 63%;
    flex-basis: 63%;
  }
  .heading, .summary {
    /* These rules are required for -webkit-line-clamp */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow-y: hidden;
  }
  .heading {
    font-size: var(--font-size-8);
    padding-right: var(--spacing-14);
    -webkit-line-clamp: 2;
  }
  .summary {
    padding-right: var(--spacing-14);
    font-weight: 400;
    font-size: var(--font-size-3);
    line-height: 1.7;
    color: var(--text-color-dark);
    -webkit-line-clamp: 3;
  }
  .ctaLogoWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .logo {
    display: block;
  }
}

@media (--screen-xl) {
  .image {
    width: 34%;
    flex-basis: 34%;
  }
  .image + .textPane {
    width: 66%;
    flex-basis: 66%;
  }
}
</style>
