<template>
  <div class="IconList">
    <SanityImage :asset="icon.asset" class="icon" />
    <p class="text">{{ text }}</p>
  </div>
</template>

<script>
export default {
  props: {
    icon: Object,
    text: String,
  },
}
</script>

<style scoped>
.IconList {
  display: flex;
  align-items: flex-start;
  margin-top: var(--spacing-8);
}
.icon {
  margin: var(--spacing-1) var(--spacing-2) 0;
}
.text {
  line-height: 1.9;
  margin-top: 0 !important;  /* Override default block content <p> spacing */
  margin-left: var(--spacing-6);
}
</style>
