<template>
  <div class="BodyChunk">
    <div v-if="body" class="body">
      <DefaultBlockContent :blocks="body" useCheckIconBullets />
    </div>
    <div v-if="cta && cta.text" class="ctaWrapper">
      <CtaButton v-if="ctaOptions.form === 'button'" :class="ctaOptions.colorClass" v-bind="cta" />
      <CtaLink v-else v-bind="cta" />
    </div>
    <div v-else-if="ctas" class="ctaWrapper multiple">
      <CtaLink v-for="cta in ctas" :key="cta._key" v-bind="cta" />
    </div>
  </div>
</template>

<script>
import DefaultBlockContent from '../../elems/DefaultBlockContent.vue'

export default {
  components: {
    DefaultBlockContent,
  },
  props: {
    body: {
      type: Array,
      default: Array,
    },
    cta: {
      type: Object,
      default: null,
    },
    ctaOptions: {
      type: Object,
      default: Object,
    },
    ctas: {
      type: Array,
      default: null,
    },
  }
}
</script>

<style scoped>
.body + .ctaWrapper {
  margin-top: var(--spacing-8);
}
.ctaWrapper.multiple {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-5);
  align-items: flex-start;
}
</style>
