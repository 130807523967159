<template>
  <Container tagName="section" class="ResourceDownloads">
    <DoubleColumnTextBox class="textBox" v-bind="{ content }" />
    <div class="tileGrid">
      <DownloadTile v-for="resource in content.resources" :key="resource._key"
        :content="resource" />
    </div>
    <div v-if="content.lowerCta && content.lowerCta.text" class="lowerCta">
      <CtaButton v-bind="content.lowerCta" class="--dark-blue" />
    </div>
  </Container>
</template>

<script>
import DoubleColumnTextBox from '../blocks/DoubleColumnTextBox.vue'
import DownloadTile from './DownloadTile.vue'

export default {
  components: {
    DownloadTile,
    DoubleColumnTextBox,
  },
  props: {
    content: {
      type: Object,
      default: Object,
    },
  },
}
</script>

<style scoped>

.ResourceDownloads {
  padding-top: var(--spacing-15);
  padding-bottom: var(--spacing-15);
}

.tileGrid {
  display: grid;
  row-gap: var(--spacing-8);
}

.textBox + .tileGrid {
  margin-top: var(--spacing-12);
}

.lowerCta {
  margin-top: var(--spacing-12);
  display: flex;
  justify-content: flex-start;
}

@media (--screen-lg) {

  .tileGrid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: var(--spacing-10);
  }

  .lowerCta {
    justify-content: center;
  }
}
</style>
