<template>
  <Container tagName="section" class="SupportOverviewSection">
    <div class="text">
      <h2 class="heading">{{ content.heading }}</h2>
      <div v-if="content.body" class="body">
        <DefaultBlockContent :blocks="content.body" />
      </div>
      <div class="ctas" v-if="content.ctas">
        <CtaLink v-for="cta in content.ctas" :key="cta._key" v-bind='cta' class="cta" />
      </div>
    </div>
    <div class="panels">
      <SupportOverviewPanel v-for="panel in content.panels" :key="panel.key" :content="panel" class="panel" />
    </div>
  </Container>
</template>

<script>
import DefaultBlockContent from '../elems/DefaultBlockContent.vue'
import SupportOverviewPanel from './SupportOverviewPanel.vue'

export default {
  components: {
    DefaultBlockContent,
    SupportOverviewPanel,
  },
  props: {
    content: {
      type: Object,
      default: Object,
    },
  },
}
</script>

<style scoped>

.SupportOverviewSection {
  padding-top: var(--spacing-15);
  padding-bottom: var(--spacing-13);
}

.text {
  text-align: center;
}

.heading {
  font-weight: 600;
  font-size: var(--font-size-9);
  color: var(--text-color-black);
}

.body {
  margin: var(--spacing-6) auto 0;
  max-width: 54rem;
  font-weight: 400;
  font-size: var(--font-size-3);
  line-height: 1.7;
  color: var(--text-color-dark);
}

.ctas {
  margin-top: var(--spacing-9);
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--spacing-2);
}

.panels {
  margin-top: var(--spacing-14);
}

.panel + .panel {
  margin-top: var(--spacing-5);
}

@media (--screen-lg) {

  .ctas {
    flex-direction: row;
    justify-content: center;
    column-gap: var(--spacing-14);
  }

  .panels {
    display: flex;
    gap: var(--spacing-5);
  }

  .panel {
    flex-basis: 50%;
  }

  .panel + .panel {
    margin-top: 0;
  }
}
</style>
