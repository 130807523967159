// This file defines the routes for this repo's preview server (`yarn serve`),
// and doesn't affect the published package in any way.

import createSanityClient from '@sanity/client'

import BodySectionExamples from '../BodySectionExamples.vue'

const dummyBreadcrumbs = [
  { title: 'Home', path: '/' },
  { title: 'Parent path', path: '/' },
  { title: 'Page path', path: '/' },
]

export default async function makeRoutes(sanityConfig, samplePages) {

  const client = createSanityClient({
    ...sanityConfig,
    apiVersion: '2022-01-01',
    useCdn: false,
  })

  const defaultRoute = {
    path: '/',
    redirect: samplePages.find(({ isDefault }) => isDefault).path,
  }

  const pageFetchPromises = samplePages.map(({ id, component }) => client.fetch(component.contentQuery, { id }))
  const documents = await Promise.all(pageFetchPromises)
  const samplePageRoutes = samplePages.map(({ path, component }, index) => ({
    path,
    component,
    props: {
      content: documents[index],
      breadcrumbs: dummyBreadcrumbs,
    },
  }))

  const bodySectionsRoute = {
    path: '/body-sections/',
    component: BodySectionExamples,
    props: {
      documentsWithBodySections: await client.fetch(`*[defined(bodySections)] { _id, _type, locale, title, bodySections }`),
    },
  }

  return [defaultRoute, ...samplePageRoutes, bodySectionsRoute]
}
