<template>
  <div class="HeroWithBackgroundImage">

    <div class="mobileView">
      <Container>
        <HeroBlurb class="text" v-bind="{ content }" colorMode="light" />
      </Container>
      <div class="image">
        <SanityImage v-if="content.backgroundImageMobile" :asset="content.backgroundImageMobile.asset" />
        <SanityImage v-else-if="content.backgroundImage" :asset="content.backgroundImage.asset" />
      </div>
    </div>

    <SanityImageBackground :asset="content.backgroundImage.asset" class="desktopView" :class="colorMode">
      <Container class="contentPane" :class="{ hasOverlay }">
        <HeroBlurb class="text" v-bind="{ content, colorMode }" :useH1="false" />
      </Container>
    </SanityImageBackground>
  </div>
</template>

<script>
import HeroBlurb from './HeroBlurb.vue'

export default {
  components: {
    HeroBlurb,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  computed: {
    colorMode() {
      return this.content.presentationOptions?.textColor == 'dark' ? 'light' : 'dark';
    },
    hasOverlay() {
      return this.content.presentationOptions?.hasOverlay
    },
  },
}
</script>

<style scoped>
.HeroWithBackgroundImage {
  padding-top: var(--spacing-15);
}

.mobileView .image {
  margin-top: var(--spacing-13);
}

.mobileView .image img {
  object-fit: cover;
  min-height: 230px;
}

.desktopView {
  display: none;
}

.desktopView.light {
  background-color: var(--bg-color-white);
}

.desktopView.dark {
  background-color: var(--bg-color-dark-blue);
}

@media (--screen-md) {
  .HeroWithBackgroundImage {
    padding-top: 0;
    padding-bottom: 0;
  }
  .mobileView {
    display: none;
  }
  .desktopView {
    display: block;
    background-position-y: center;
    background-position-x: right;
  }
  .hasOverlay {
    background-color: transparent;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 35%, transparent 65%);
  }
  .contentPane {
    padding-top: var(--spacing-17);
    padding-bottom: var(--spacing-17);
  }
  .text {
    max-width: 70%;
  }
}

@media (--screen-lg) {
  .contentPane {
    padding-top: var(--spacing-16);
    padding-bottom: var(--spacing-16);
  }
  .text {
    max-width: 50%;
  }
}

@media screen and (min-width: 1600px) {
  .contentPane {
    padding-top: var(--spacing-17);
    padding-bottom: var(--spacing-17);
  }
}

@media screen and (min-width: 2200px) {
  .contentPane {
    padding-top: var(--spacing-19);
    padding-bottom: var(--spacing-19);
  }
}
</style>
