<template>
  <Container class="LogoStripModule" :style="{ backgroundColor }">
    <FluidLogoStrip :content="content" />
  </Container>
</template>

<script>
import FluidLogoStrip from '../elems/FluidLogoStrip.vue'

export default {
  components: {
    FluidLogoStrip,
  },
  props: {
    content: Object,
  },
  computed: {
    backgroundColor() {
      switch (this.content.presentationOptions?.backgroundColor) {
      case 'dark-blue':
        return 'var(--bg-color-dark-blue)';
      case 'pale-gray':
        return 'var(--bg-color-pale-gray)'
      default:
        return 'var(--bg-color-white)';
      }
    },
  },
}
</script>
