<template>
  <Container tagName="section" class="BillboardSection">
    <Billboard v-bind="{ content }" />
  </Container>
</template>

<script>
import Billboard from '../elems/Billboard.vue'

export default {
  components: {
    Billboard,
  },
  props: {
    content: Object,
  },
}
</script>

<style scoped>
.BillboardSection {
  padding-top: var(--spacing-15);
  padding-bottom: var(--spacing-15);
}
</style>
