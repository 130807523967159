<template>
  <div class="SupportOverviewPanel">
    <div class="icon">
      <SanityImage :asset="content.icon.asset" />
    </div>
    <h3 class="heading">{{ content.heading }}</h3>
    <div class="body">
      <DefaultBlockContent :blocks="content.body" />
    </div>
    <CtaButton v-if="content.cta" v-bind="content.cta" class="ctaButton --bright-blue" />
  </div>
</template>

<script>
import DefaultBlockContent from '../elems/DefaultBlockContent.vue'

export default {
  components: {
    DefaultBlockContent,
  },
  props: {
    content: {
      type: Object,
      default: Object,
    },
  },
}
</script>

<style scoped>

.SupportOverviewPanel {
  border-radius: 10px;
  padding: var(--spacing-12) var(--spacing-8) var(--spacing-11);
  background-color: var(--bg-color-dark-blue);
}

.icon {
  height: 4rem;
}

.heading {
  margin-top: var(--spacing-6);
  font-weight: 600;
  font-size: var(--font-size-5);
  color: var(--text-color-white);
}

.body {
  margin-top: var(--spacing-5);
  --text-color: var(--text-color-white);
}

.ctaButton {
  margin-top: var(--spacing-9);
}
</style>
