<template>
  <Container class="FaqSection">
    <div class="header">
      <h2 class="heading">{{ content.heading }}</h2>
    </div>
    <div class="faqs">
      <details
        ref="faqs"
        v-for="(faq, index) in content.faqs"
        :key="faq._key"
        class="faqItem"
        :open="index === 0"
      >
        <summary class="faqQuestion">
          <h3>{{ faq.question }}</h3>
          <ExpandPlusMinus class="faqQuestionIcon" vStrokeClass="faqQuestionIconVStroke" />
        </summary>
        <DefaultBlockContent v-if="faq.answer" :blocks="faq.answer" class="faqAnswer" />
      </details>
    </div>
    <aside class="ctaBlock" v-if="content.includeCtaBlock">
      <h3 class="ctaTitle">{{ content.ctaBlock.title }}</h3>
      <p class="ctaText">{{ content.ctaBlock.text }}</p>
      <CtaButton class="ctaButton --dark-blue" v-bind="content.ctaBlock.cta" />
    </aside>
    <script v-if="content.faqs" v-html="faqSchema" type="application/ld+json"></script>
  </Container>
</template>

<script>
import DefaultBlockContent from '../elems/DefaultBlockContent.vue'
import ExpandPlusMinus from '../icons/ExpandPlusMinus.vue'
import { toHTML } from '@portabletext/to-html'

export default {
  components: {
    DefaultBlockContent,
    ExpandPlusMinus,
  },
  props: {
    content: Object,
  },
  computed: {
    faqSchema() {
      let faqs = []

      this.content.faqs.forEach((faq) => {
        faqs.push({
          '@type': 'Question',
          name: faq.question,
          acceptedAnswer: {
            '@type': 'Answer',
            text: toHTML(faq.answer),
          },
        })
      })

      const faqSchema = {
        "@context": "http://schema.org",
        "@type": "FAQPage",
        "mainEntity": faqs,
      }

      return faqSchema
    },
  },
}
</script>

<style scoped>
.FaqSection {
  padding-top: var(--spacing-14);
  padding-bottom: var(--spacing-16);
}
.FaqSection:not(.--no-border) {
  border-top: thin solid var(--hairline-color);
}
.heading {
  color: var(--text-color-black);
  font-weight: 600;
  font-size: var(--font-size-9);
  line-height: 1;
}
.faqItem {
  border: thin solid var(--hairline-color);
  border-radius: 10px;
}
.faqItem[open] .faqQuestion {
  padding-bottom: 0;
}
.faqQuestion {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-color-black);
  font-size: var(--font-size-4);
  cursor: pointer;
  transition: all 0.1s;
  outline: none;
  padding: var(--spacing-9) var(--spacing-10);
}
.faqQuestion h3 {
  padding-right: var(--spacing-9);
  outline: none;
}
.faqQuestionIcon {
  flex-shrink: 0;
}
.faqAnswer {
  padding: 0 var(--spacing-10) var(--spacing-9);
}
.faqQuestionIconVStroke {
  transform-origin: center;
  transition: transform 0.1s;
}
.faqItem[open] {
  box-shadow: 0px 6px 10px 0px #00000008;
}
.faqItem[open] .faqQuestion {
  color: var(--brand-color);
}
.faqItem[open] >>> .faqQuestionIconVStroke {
  transform: scaleY(0);
}
.ctaBlock {
  text-align: center;
  color: var(--text-color-dark);
  font-weight: normal;
  font-size: var(--font-size-3);
  line-height: 1.7;
}
.ctaTitle {
  color: var(--text-color-black);
  font-weight: 600;
  font-size: var(--font-size-5);
  line-height: 1.4;
}
.header + .faqs {
  margin-top: var(--spacing-12);
}
.faqItem + .faqItem {
  margin-top: var(--spacing-3);
}
.faqQuestion + .faqAnswer {
  margin-top: var(--spacing-3);
}
.faqs + .ctaBlock {
  margin-top: var(--spacing-14);
}
.ctaTitle + .ctaText {
  margin-top: var(--spacing-3);
}
.ctaText + .ctaButton {
  margin-top: var(--spacing-7);
}

@media (--screen-lg) {
  .header {
    text-align: center;
    max-width: 720px;
    margin: 0 auto;
  }

  .faqs {
    margin-left: auto;
    margin-right: auto;
    max-width: 1024px;
  }

  .faqAnswer {
    padding-right: var(--spacing-16);
  }

  .ctaBlock {
    max-width: 640px;
    margin: auto;
  }
}
</style>
