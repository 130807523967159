<template>
  <div class="Billboard">
    <SanityImage v-if="content.image" :asset="content.image.asset" class="image" />
    <div class="textPane">
      <h3 class="title">{{ content.title }}</h3>
      <CtaButton v-if="content.cta" v-bind="content.cta" icon="arrow" class="cta --bright-blue" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: Object,
  },
}
</script>

<style scoped>
.Billboard {
  border-radius: 5px;
  overflow: hidden;
}
.textPane {
  padding: var(--spacing-15) var(--spacing-10);
  background-color: var(--bg-color-dark-blue);
}
.title {
  font-weight: 600;
  font-size: var(--font-size-8);
  line-height: 1.3;
  color: var(--text-color-white);
}
.title + .cta {
  margin-top: var(--spacing-15);
}

@media (--screen-lg) {
  .Billboard {
    border-radius: 10px;
    display: flex;
    flex-direction: row-reverse;
  }
  .image, .textPane {
    width: 50%;
    flex-basis: 50%;
  }
  .image {
    object-fit: cover;
  }
  .textPane {
    align-self: stretch;
    padding: 1rem 6%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .title {
    font-size: 2.5rem;
    max-width: 11em;
  }
  .title + .cta {
    margin-top: var(--spacing-8);
  }
}
</style>
