<template>
  <Container tagName="section" class="ContentSection">
    <div class="mediaChunk" :class="isMediaAlignedRight ? 'toRight' : 'toLeft'">
      <AnyMedia class="media" :media="content.media" />
    </div>
    <div class="textBox" :class="isMediaAlignedRight ? 'toLeft' : 'toRight'">
      <HeadingChunk :heading="content.heading" :label="content.label" class="headingChunk" />
      <div class="bodyChunk">
        <div v-if="content.body" class="body">
          <DefaultBlockContent :blocks="content.body" useCheckIconBullets />
        </div>
        <div v-if="content.cta && content.cta.text" class="ctaWrapper">
          <CtaButton class="--dark-blue" v-bind="content.cta" />
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import HeadingChunk from '../bodySections/blocks/HeadingChunk.vue'
import AnyMedia from '../elems/AnyMedia.vue'
import DefaultBlockContent from '../elems/DefaultBlockContent.vue'

export default {
  components: {
    AnyMedia,
    DefaultBlockContent,
    HeadingChunk,
  },
  props: {
    content: Object,
  },
  computed: {
    isMediaAlignedRight() {
      return this.content.presentationOptions?.imageAlign === 'right'
    },
  },
}
</script>

<style scoped>

.ContentSection {
  background-color: var(--background-color);
  color: var(--text-color);
  padding-bottom: var(--spacing-15);
  padding-top: var(--spacing-15);
}

.mediaChunk + .textBox {
  margin-top: var(--spacing-10);
}

.headingChunk + .bodyChunk {
  margin-top: var(--spacing-7);
}

.media >>> img,
.media >>> video {
  border-radius: var(--border-radius);
}

.body + .ctaWrapper {
  margin-top: var(--spacing-8);
}

@media (--screen-lg) {

  .mediaChunk + .textBox {
    margin-top: 0;
  }

  .ContentSection {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: var(--spacing-6);
  }

  .textBox .bodyChunk {
    max-width: 36rem;
  }

  .mediaChunk {
    max-width: 520px;
  }

  .textBox, .mediaChunk {
    grid-row: 1;
    align-self: center;
  }

  .textBox.toLeft {
    grid-column: 1;
  }

  .textBox.toRight {
    grid-column: 2;
  }

  .mediaChunk.toLeft {
    grid-column: 1;
    justify-self: left;
  }

  .mediaChunk.toRight {
    grid-column: 2;
    justify-self: right;
  }
}
</style>
