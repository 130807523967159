<template>
  <LogoStrip :class="$style.FluidLogoStrip" v-bind="{ content }" />
</template>

<script>
import LogoStrip from './LogoStrip.vue'

export default {
  components: {
    LogoStrip,
  },
  props: {
    content: Object,
  },
}
</script>

<style module>
.FluidLogoStrip {
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: var(--spacing-9);
}
@media (--screen-lg) {
  .FluidLogoStrip {
    justify-content: space-between;
  }
}
</style>
