<template>

  <Container tagName="header" class="HeroWithInlineMedia" :class="colorMode">

    <div class="heroContent" :class="[
      isImageAlignedRight ? 'alignImageRight' : 'alignImageLeft',
      isImageWide ? 'wideImage' : 'narrowImage',
    ]">
      <HeroBlurb class="text" v-bind="{ content, colorMode }" />
      <AnyMedia class="mediaChunk" :media="content.media" />
    </div>

  </Container>

</template>

<script>
import AnyMedia from '../elems/AnyMedia.vue'
import HeroBlurb from './HeroBlurb.vue'

export default {
  components: {
    HeroBlurb,
    AnyMedia
  },
  props: {
    content: Object,
  },
  computed: {
    colorMode() {
      return this.content.presentationOptions?.backgroundColor === 'dark-blue' ? 'dark' : 'light'
    },
    isImageAlignedRight() {
      return this.content.presentationOptions?.imageAlign === 'right'
    },
    isImageWide() {
      return this.content.presentationOptions?.width === 'wide'
    },
  },
}
</script>

<style scoped>

.HeroWithInlineMedia {
  padding-top: var(--spacing-15);
  padding-bottom: var(--spacing-15);
}

.HeroWithInlineMedia.light {
  background-color: var(--bg-color-white);
}

.HeroWithInlineMedia.dark {
  background-color: var(--bg-color-dark-blue);
}

.text + .mediaChunk {
  margin-top: var(--spacing-13);
}

.mediaChunk >>> img,
.mediaChunk >>> video {
  border-radius: var(--border-radius);
}

@media (--screen-lg) {

  .HeroWithInlineMedia .heroContent {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 1rem;
    align-items: center;
  }

  .HeroWithInlineMedia .heroContent .text,
  .HeroWithInlineMedia .heroContent .mediaChunk {
    grid-row: 1;
    align-self: center;
  }
  .HeroWithInlineMedia .heroContent.alignImageLeft.narrowImage .text {
    grid-column: 7 / span 5;
  }
  .HeroWithInlineMedia .heroContent.alignImageLeft.narrowImage .mediaChunk {
    grid-column: 2 / span 4;
  }
  .HeroWithInlineMedia .heroContent.alignImageLeft.wideImage .text {
    grid-column: 8 / span 5;
  }
  .HeroWithInlineMedia .heroContent.alignImageLeft.wideImage .mediaChunk {
    grid-column: 1 / span 6;
  }

  .HeroWithInlineMedia .heroContent.alignImageRight.narrowImage .text {
    grid-column: 2 / span 5;
  }
  .HeroWithInlineMedia .heroContent.alignImageRight.narrowImage .mediaChunk {
    grid-column: 8 / span 4;
  }
  .HeroWithInlineMedia .heroContent.alignImageRight.wideImage .text {
    grid-column: 1 / span 5;
  }
  .HeroWithInlineMedia .heroContent.alignImageRight.wideImage .mediaChunk {
    grid-column: 7 / span 6;
  }

  .text + .mediaChunk {
    margin-top: 0;
  }
}
</style>
