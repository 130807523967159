import { render, staticRenderFns } from "./FinePrint.vue?vue&type=template&id=961e5b34&scoped=true&"
import script from "./FinePrint.vue?vue&type=script&lang=js&"
export * from "./FinePrint.vue?vue&type=script&lang=js&"
import style0 from "./FinePrint.vue?vue&type=style&index=0&id=961e5b34&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "961e5b34",
  null
  
)

export default component.exports