<template>
  <button class="CloseButton" aria-label="Close" v-html="closeSvg" v-on="$listeners"></button>
</template>

<script>
import closeSvg from '!raw-loader!../../assets/images/close.svg'

export default {
  data() {
    return { closeSvg }
  },
}
</script>
