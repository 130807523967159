<template>
  <HorizontalScroller class="FeatureCards">
    <Container class="cardLineup">
      <div v-for="feature in features" :key="feature.title" class="card">
        <div class="icon" v-if="feature.icon">
          <SanityImage :asset="feature.icon.asset" />
        </div>
        <h3 class="heading">{{ feature.heading }}</h3>
        <div class="body">
          <BlockContent :blocks="feature.body" />
        </div>
        <CtaLink v-bind="feature.cta" class="cta" />
      </div>
    </Container>
  </HorizontalScroller>
</template>

<script>
import BlockContent from 'sanity-blocks-vue-component'
import HorizontalScroller from '../../../elems/HorizontalScroller.vue'

export default {
  components: {
    BlockContent,
    HorizontalScroller,
  },
  props: {
    features: {
      type: Array,
      default: Array,
    },
  },
}
</script>

<style scoped>
.cardLineup {
  display: flex;
  min-width: fit-content;
  column-gap: var(--spacing-5);
}
.card {
  background-color: var(--bg-color-white);
  border: thin solid var(--hairline-color);
  border-radius: 8px;
  padding: var(--spacing-9) var(--spacing-10);
  min-width: 19rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.heading {
  font-weight: 600;
  font-size: var(--font-size-4);
  color: var(--text-color-black);
}
.body {
  max-width: 16em;
  font-weight: 400;
  font-size: var(--font-size-3);
  line-height: 1.6;
  color: var(--text-color-dark);
}
.body >>> strong {
  font-weight: 600;
}
.icon + .heading {
  margin-top: var(--spacing-7);
}
.heading + .body {
  margin-top: var(--spacing-2);
  margin-bottom: auto;
}
.body + .cta {
  margin-top: var(--spacing-7);
}
</style>
