<template>
  <aside class="ColorfulGrid" :class="colorMode">
    <Container class="head mobile-only">
      <div v-if="content.label" class="label">{{ content.label }}</div>
      <h2 class="heading">{{ content.heading }}</h2>
    </Container>

    <HorizontalScroller>
      <Container class="contentPane">
        <div class="contentSlider">
          <div class="head desktop-only">
            <div v-if="content.label" class="label">{{ content.label }}</div>
            <h2 class="heading">{{ content.heading }}</h2>
          </div>
          <div class="item"
            v-for="{ _key, icon, heading, body, } in content.items"
            :key="_key"
          >
            <div v-if="icon" class="icon">
              <SanityImage class="iconImg" :asset="icon.asset" width="90" />
            </div>
            <h3 class="subheading">{{ heading }}</h3>
            <div v-if="body" class="body">
              <BlockContent :blocks="body" />
            </div>
          </div>
        </div>
      </Container>
    </HorizontalScroller>
  </aside>
</template>

<script>
import BlockContent from 'sanity-blocks-vue-component'
import HorizontalScroller from '../elems/HorizontalScroller.vue'

export default {
  components: {
    BlockContent,
    HorizontalScroller,
  },
  props: {
    content: {
      type: Object,
      default() { return {} },
    },
  },
  computed: {
    colorMode() {
      return this.content.presentationOptions?.backgroundColor === 'dark-blue' ? 'dark' : 'light'
    }
  },
}
</script>

<style scoped>
  .ColorfulGrid {
    padding-top: var(--spacing-15);
    padding-bottom: var(--spacing-15);
  }
  .ColorfulGrid.light {
    background-color: var(--bg-color-pale-blue);
    color: var(--text-color-dark);
  }
  .ColorfulGrid.light .heading {
    color: var(--text-color-black);
  }
  .ColorfulGrid.light .heading, .ColorfulGrid.light .subheading {
    color: var(--text-color-black);
  }
  .ColorfulGrid.dark {
    background-color: var(--bg-color-dark-blue);
    color: var(--text-color-light);
  }
  .ColorfulGrid.dark .heading, .ColorfulGrid.dark .subheading {
    color: var(--text-color-white);
  }
  .contentPane {
    margin-top: var(--spacing-9);
  }
  .contentSlider {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing-8);
    min-width: 1024px;
  }
  .iconImg {
    border-radius: var(--border-radius);
    overflow: hidden;
  }
  .label {
    font-weight: 600;
    font-size: var(--font-size-1);
    line-height: 1;
    text-transform: uppercase;
    color: var(--brand-color);
  }
  .heading {
    font-weight: 600;
    font-size: var(--font-size-7);
    line-height: 1.2;
  }
  .label + .heading {
    margin-top: var(--spacing-3);
  }
  .head.desktop-only {
    display: none;
  }
  .subheading {
    font-weight: 600;
    font-size: var(--font-size-4);
    line-height: 1.2;
  }
  .body {
    font-weight: normal;
    font-size: var(--font-size-3);
    line-height: 1.7;
    max-width: 20em;
  }
  .icon + .subheading {
    margin-top: 1.375rem;
  }
  .subheading + .body {
    margin-top: 1rem;
  }
  @media (--screen-lg) {
    .head.mobile-only {
      display: none;
    }
    .head.desktop-only {
      display: block;
    }
    .contentPane {
      margin-top: 0;
    }
    .contentSlider {
      gap: var(--spacing-11) var(--spacing-8);
    }
  }
</style>
