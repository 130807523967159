<template>
  <main>
    <Hero :content="content.hero" />
    <CountdownTimer :content="content.countdownTimer" />
    <CollapsibleFeatureListing :content="content.collapsibleFeatureListing" />
    <InPageForm :content="content.inPageForm" :id="content.inPageForm.sectionId" />
    <FaqSection :content="content.faqSection" :id="content.faqSection.sectionId" />
    <FooterCta v-if="content.footerCta" :content="content.footerCta" />
  </main>
</template>

<script>
import CollapsibleFeatureListing from '../bodySections/CollapsibleFeatureListing/index.vue'
import CountdownTimer from './CountdownTimer.vue'
import FaqSection from '../bodySections/FaqSection.vue'
import FooterCta from '../headerFooterSections/FooterCta.vue'
import Hero from './Hero.vue'
import InPageForm from '../bodySections/InPageForm/index.vue'

export default {
  components: {
    CollapsibleFeatureListing,
    CountdownTimer,
    FaqSection,
    FooterCta,
    Hero,
    InPageForm,
  },
  props: {
    content: Object,
  },
  provide() {
    return {
      locale: () => this.content?.locale,
    }
  },
  contentQuery: `
    *[_id == $id][0]{
      locale,
      hero,
      countdownTimer,
      collapsibleFeatureListing,
      inPageForm,
      faqSection,
      footerCta->{ title, text, cta },
    }
  `,
}
</script>
