<template>
  <main class="LegalDocumentPage">
    <HeroWithTextOnly :breadcrumbs="breadcrumbs" :content="heroContent" />
    <Container tagName="section" class="bodyContainer">
      <DefaultBlockContent v-if="content.body" :blocks="content.body" class="body" />
    </Container>
    <FooterCta v-if="content.footerCta" :content="content.footerCta" />
  </main>
</template>

<script>
import DefaultBlockContent from '../elems/DefaultBlockContent.vue'
import FooterCta from '../headerFooterSections/FooterCta.vue'
import HeroWithTextOnly from '../headerFooterSections/HeroWithTextOnly.vue'

export default {
  components: {
    DefaultBlockContent,
    FooterCta,
    HeroWithTextOnly,
  },
  props: {
    breadcrumbs: Array,
    content: Object,
  },
  computed: {
    heroContent() {
      return {
        heading: this.content.heading,
        subtitle: this.content.subtitle,
      }
    },
  },
  contentQuery: `
    *[_id == $id][0]{
      heading,
      subtitle,
      body,
      footerCta->{ title, text, cta },
    }
  `,
}
</script>

<style scoped>

.bodyContainer {
  padding-top: var(--spacing-15);
  padding-bottom: var(--spacing-15);
}

.body >>> h2 {
  font-size: var(--font-size-8);
}

.body >>> h3 {
  font-size: var(--font-size-7);
}

.body >>> h4 {
  font-size: var(--font-size-6);
}

.body >>> h5 {
  font-size: var(--font-size-5);
}

.body >>> h6 {
  font-size: var(--font-size-4);
}

@media (--screen-lg) {

  .body >>> h2 {
    font-size: var(--font-size-10);
  }

  .body >>> h3 {
    font-size: var(--font-size-8);
  }

  .body >>> h4 {
    font-size: var(--font-size-7);
  }

  .body >>> h5 {
    font-size: var(--font-size-5);
  }

  .body >>> h6 {
    font-size: var(--font-size-4);
  }
}
</style>
