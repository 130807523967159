<template>
  <div class="ProductFeatureDocumentation">
    <h3 v-if="content.heading">{{ content.heading }}:</h3>
    <div class="documentationContent">
      <a
        v-for="item in content.files"
        :key="item._key"
        class="file"
        :href="item.url"
        target="_blank"
      >
        <SanityImage v-if="item.icon" class="icon" :asset="item.icon.asset" />
        <p class="title">{{ item.title }}</p>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: Object,
  },
}
</script>

<style scoped>
.ProductFeatureDocumentation h3 {
  color: var(--text-color-black);
  font-weight: 600;
  font-size: var(--font-size-3);
  line-height: 1.7;
  margin-bottom: var(--spacing-8);
}

.documentationContent {
  display: grid;
  grid-template-columns: max-content;
  gap: var(--spacing-4);
}

.file {
  display: flex;
  flex-flow: row;
  align-items: center;
  border: 1px solid #EEEEEE;
  border-radius: 5px;
  padding: var(--spacing-4) var(--spacing-5);
  cursor: pointer;
}
.icon {
  flex-shrink: 0;
}
.title {
  font-size: var(--font-size-3);
  line-height: 1.7;
}
.icon + .title {
  margin-left: var(--spacing-5);
}

@media (--screen-md) {
  .file {
    border-radius: 10px;
  }
}

@media (--screen-lg) {
  .ProductFeatureDocumentation {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 1rem;
  }
  .ProductFeatureDocumentation h3 {
    grid-column: 1 / span 4;
    margin-right: var(--spacing-18);
    margin-bottom: 0;
  }
  .documentationContent {
    grid-column: 5 / span 8;
  }
}
</style>
