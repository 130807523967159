<template>
  <picture class="SanityPicture">
    <SanityPictureSource v-for="image in otherImages"
      :key="image._key"
      :asset="image.asset"
      :media="customMedia[image.screenSize]"
    />
    <SanityImage v-if="baseImage" :asset="baseImage.asset" :alt="baseImage.alt" />
  </picture>
</template>

<script>
import customMedia from '@inseego-digital/baseline-styles/custom-media.json'

export default {
  props: {
    baseImage: {
      type: Object,
      default: null,
    },
    otherImages: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      customMedia
    }
  },
}
</script>
