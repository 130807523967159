<template>
  <Container tagName="section" class="QuoteCallout" :style="{ backgroundColor }">
    <div class="layoutBox">
      <SanityImage v-if="content.image" :asset="content.image.asset" class="image" />
      <div class="textPane">
        <div>
          <div class="quoteMark">
            <img src="../../assets/images/quote.svg" width="102" height="78" />
          </div>
          <div>
            <div v-if="content.quoteText" class="body">
              <DefaultBlockContent :blocks="content.quoteText" useCheckIconBullets class="quoteText" />
            </div>
            <div v-if="content.quotePersonName || content.quotePersonTitle || content.logo" class="ctaLogoWrapper">
              <div>
                <p class="quotePersonName">{{ content.quotePersonName }}</p>
                <p class="quotePersonTitle">{{ content.quotePersonTitle }}</p>
              </div>
              <ScaledImage v-if="content.logo" :asset="content.logo.asset" :alt="content.heading" :diagonalLength="175" class="logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import ColorContext from '../elems/ColorContext.vue'
import DefaultBlockContent from '../elems/DefaultBlockContent.vue'
import ScaledImage from '../elems/ScaledImage.vue'

export default {
  components: {
    ColorContext,
    DefaultBlockContent,
    ScaledImage,
  },
  props: {
    content: Object,
  },
  computed: {
    backgroundColor() {
      switch (this.content.presentationOptions?.backgroundColor) {
        case 'pale-blue':
          return 'var(--bg-color-pale-blue)'
        default:
          return 'var(--bg-color-brand-blue)'
      }
    },
    ctaColorClass() {
      return this.content.presentationOptions?.backgroundColor === 'pale-blue' ? '--bright-blue' : '--dark-blue';
    },
  },
}
</script>

<style scoped>
.QuoteCallout {
  color: var(--text-color-dark);
  padding-bottom: var(--spacing-15);
  padding-top: var(--spacing-15);
}
.layoutBox {
  border-radius: var(--border-radius);
  overflow: hidden;
}
.textPane {
  padding: var(--spacing-10);
  background-color: var(--bg-color-white);
}
.body + .ctaLogoWrapper {
  margin-top: var(--spacing-8);
}
.quoteMark, .logo {
  display: none;
}
.quoteText {
  font-size: var(--font-size-4);
}
.quotePersonName {
  font-size: var(--font-size-3);
  font-weight: 600;
}
.quotePersonTitle {
  font-size: var(--font-size-2);
}

@media (--screen-lg) {
  .layoutBox {
    display: flex;
  }
  .image {
    width: 37%;
    flex-basis: 37%;
    object-fit: cover;
  }
  .textPane {
    align-self: stretch;
    padding: var(--spacing-10);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .image + .textPane {
    width: 63%;
    flex-basis: 63%;
  }
  .textPane > div {
    display: flex;
  }
  .quoteMark {
    display: block;
    flex-shrink: 0;
    padding-right: var(--spacing-10);
  }
  .quotePersonName + .quotePersonTitle {
    margin-top: var(--spacing-1);
  }
  .ctaLogoWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .logo {
    display: block;
  }
}

@media (--screen-xl) {
  .image {
    width: 34%;
    flex-basis: 34%;
  }
  .image + .textPane {
    width: 66%;
    flex-basis: 66%;
  }
}
</style>
