<template>
  <div class="ProductFeatureList">
    <h3 v-if="content.heading">{{ content.heading }}:</h3>
    <div class="listContent">
      <div
        v-for="item in content.operatingSystems"
        :key="item._key"
        class="listItem"
      >
        <SanityImage v-if="item.icon" class="icon" :asset="item.icon.asset" />
        <p class="title">{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: Object,
  },
}
</script>

<style scoped>
.ProductFeatureList h3 {
  color: var(--text-color-black);
  font-weight: 600;
  font-size: var(--font-size-3);
  line-height: 1.7;
  margin-bottom: var(--spacing-8);
}
.listItem {
  display: flex;
  flex-flow: row;
  align-items: center;
}
.listItem + .listItem {
  margin-top: var(--spacing-6);
}

.icon {
  flex-shrink: 0;
}
.title {
  font-size: var(--font-size-3);
  line-height: 1.7;
}
.icon + .title {
  margin-left: var(--spacing-5);
}

@media (--screen-lg) {
  .ProductFeatureList {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 1rem;
  }
  .ProductFeatureList h3 {
    grid-column: 1 / span 4;
    margin-right: var(--spacing-18);
    margin-bottom: 0;
  }
  .listContent {
    grid-column: 5 / span 8;
  }
}
</style>
