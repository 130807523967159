<template>
  <SanityPicture v-bind="{ baseImage, otherImages }" class="tabPane" />
</template>

<script>
import SanityPicture from '../../elems/SanityPicture.vue'

export default {
  components: {
    SanityPicture,
  },
  props: {
    desktopImage: {
      type: Object,
      default: null,
    },
    mobileImage: {
      type: Object,
      default: null,
    },
   },
  computed: {
    baseImage() {
      return this.mobileImage || this.desktopImage
    },
    otherImages() {
      return (
        this.mobileImage
          ? [{ ...this.desktopImage, media: '--screen-lg' }]
          : []
      )
    },
  }
}
</script>

<style scoped>
.TabPane >>> img {
  border-radius: var(--border-radius);
}
</style>
