<template>
  <pre>{{ content }}</pre>
</template>

<script>
export default {
  props: {
    content: Object,
  },
}
</script>
