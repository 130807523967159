<template>
  <video class="ExternalVideo" :poster="posterImageUrl" controls playsinline :autoplay="autoplay" :muted="autoplay">
    <source v-if="videoUrl" :src="videoUrl">
  </video>
</template>

<script>
import { sanityImageUrl } from '@inseego-digital/sanity-image-components'

export default {
  props: {
    posterImage: {
      type: Object,
      default: null,
    },
    videoUrl: {
      type: String,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    posterImageUrl() {
      const imageId = this.posterImage?.asset?._ref
      if (imageId) {
        const options = {
          w: '1280',
          auto: 'format',
        }
        return sanityImageUrl(this.$config.sanity, imageId, options)
      }
      return false
    },
  },
}
</script>

<style scoped>
.ExternalVideo {
  cursor: pointer;
}
</style>
