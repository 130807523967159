<template>
  <ColorContext :backgroundColor="backgroundColor">
    <Container
      class="MinorSections"
      :style="cssVariables"
    >
      <section
        class="item"
        v-for="{ _key, icon, heading, body, cta } in content.sections"
        :key="_key"
      >
        <div v-if="icon" class="icon">
          <SanityImage :asset="icon.asset" />
        </div>
        <h3 class="subheading">{{ heading }}</h3>
        <BodyChunk v-bind="{ body, cta }" class="body" />
      </section>
    </Container>
  </ColorContext>
</template>

<script>
import BodyChunk from './blocks/BodyChunk.vue'
import ColorContext from '../elems/ColorContext.vue'

export default {
  components: {
    BodyChunk,
    ColorContext,
  },
  props: {
    content: Object,
  },
  computed: {
    backgroundColor() {
      return this.content.presentationOptions?.backgroundColor
    },
    cssVariables() {
      return {
        '--grid-template-columns':
          this.content.sections.length > 3 ? 3 : this.content.sections.length,
      }
    },
  },
}
</script>

<style scoped>

.MinorSections {
  background-color: var(--background-color);
  padding-bottom: var(--spacing-15);
  padding-top: var(--spacing-15);
}

.subheading {
  color: var(--heading-color);
  font-weight: 600;
  font-size: var(--font-size-4);
  line-height: 1.2;
}

.item + .item {
  margin-top: var(--spacing-10);
}

.icon + .subheading {
  margin-top: var(--spacing-10);
}

.subheading + .body {
  margin-top: var(--spacing-5);
}

@media (--screen-lg) {

  .MinorSections {
    display: grid;
    grid-template-columns: repeat(var(--grid-template-columns), 1fr);
    gap: var(--spacing-16) var(--spacing-5);
  }

  .item + .item {
    margin-top: 0;
  }

  .item {
    padding-right: 10%;
  }
}
</style>
