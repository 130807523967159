<template>
  <div class="IconStrip" :style="cssVariables">
    <div
      v-for="{ _key, icon, title } in content.icons"
      :key="_key"
      class="iconColumn"
    >
      <SanityImage v-if="icon" :asset="icon.asset" />
      <p>{{ title }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: Object,
  },
  computed: {
    cssVariables() {
      return {
        '--grid-template-columns':
          this.content.icons.length > 4 ? 4 : this.content.icons.length,
      }
    },
  },
}
</script>

<style scoped>

.IconStrip {
  padding-top: var(--spacing-11);
  padding-bottom: var(--spacing-11);
  display: grid;
  gap: var(--spacing-12);
}

.iconColumn {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.iconColumn img {
  flex-shrink: 0;
}

.iconColumn p {
  font-size: var(--font-size-1);
  line-height: 1.7;
  margin-left: var(--spacing-5);
}

@media (--screen-md) {
  .IconStrip {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (--screen-lg) {
  .IconStrip {
    grid-template-columns: repeat(var(--grid-template-columns), 1fr);
  }
}
</style>
