<template>
  <Container tagName="section" class="OfficeContactDetails">
    <h2 class="heading">{{ content.heading }}</h2>
    <div class="details" v-if="localeStrings">
      <div class="detailsLocation"
        v-for="{ flag, heading, entries } in officeLocations"
        :key="heading"
      >
        <div class="detailsHeading">
          <img v-if="flag" :src="require(`@/assets/flags/${flag}`)" />
          {{ heading }}
        </div>
        <div class="detailsEntries">
          <div v-for="entry in entries" :key="entry.subheadingKey">
            <h4 class="detailsSubheading">{{ localeStrings[entry.subheadingKey] }}</h4>
            <p v-if="entry.address" class="detailsText">
              <span v-for="item in entry.address" :key="item">{{ item }}</span>
            </p>
            <p v-else-if="entry.phone" class="detailsText">{{ entry.phone }}</p>
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import BlockContent from 'sanity-blocks-vue-component'
import officeLocations from '../../data/office-locations.json'
import strings from './OfficeLocationGrid.json'

export default {
  inject: ['locale'],
  components: {
    BlockContent,
  },
  props: {
    content: Object,
  },
  computed: {
    localeStrings() {
      return strings[this.locale()]
    },
  },
  data() {
    return {
      officeLocations,
    }
  },
}
</script>

<style scoped>
  .OfficeContactDetails {
    padding-top: var(--spacing-15);
    padding-bottom: var(--spacing-15);
    color: var(--text-color-dark);
  }
  .heading {
    color: var(--heading-color);
    font-weight: 600;
    font-size: var(--font-size-7);
    line-height: 1.2;
  }
  .heading + .details {
    margin-top: var(--spacing-14);
  }

  .detailsHeading {
    display: flex;
    gap: var(--spacing-5);
    font-weight: 600;
    font-size: 19px;
  }
  .detailsHeading + .detailsEntries {
    margin-top: var(--spacing-6);
  }

  .detailsEntries {
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    gap: var(--spacing-6);
  }

  .detailsSubheading {
    font-weight: 600;
    font-size: var(--font-size-3);
    line-height: 1.7;
  }
  .detailsText {
    display: flex;
    flex-direction: column;
    font-weight: normal;
    font-size: var(--font-size-3);
    line-height: 1.7;
  }
  a.detailsText {
    text-decoration: underline;
  }
  a.detailsText:hover {
    color: var(--brand-color);
  }

  .detailsLocation + .detailsLocation {
    margin-top: var(--spacing-10);
  }

  @media (--screen-lg) {
    .OfficeContactDetails .details {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: var(--spacing-14) var(--spacing-5);
    }
    .heading {
      font-size: var(--font-size-9);
    }

    .detailsLocation + .detailsLocation {
      margin-top: 0;
    }
    .detailsLocation {
      padding-right: 10%;
    }
  }
</style>
