<template>
  <Container
    :class="$style.iconStrip"
  >
    <IconStrip :content="content" />
  </Container>
</template>

<script>
import IconStrip from '../elems/IconStrip.vue'

export default {
  components: {
    IconStrip,
  },
  props: {
    content: Object,
  },
}
</script>

<style module>
.iconStrip {
  background-color: var(--bg-color-white);
}
</style>
