<template>
  <div id="app">
    <header :class="$style.placeholderHeader" />
    <router-view />
    <footer :class="$style.placeholderFooter" />
  </div>
</template>

<style src="@inseego-digital/button-styles/dist/buttonStyles.css" />

<script>

import '@inseego-digital/baseline-styles'

export default {
  name: 'App',
}
</script>

<style module>
.placeholderHeader {
  height: 5rem;
  background-color: white;
}
.placeholderFooter {
  height: 20rem;
  background-color: #051222;
}
</style>

<style>
html {
  scroll-behavior: smooth;
}
</style>
