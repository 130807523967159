<template>
  <div class="SectionTextBlock">
    <h2 v-if="heading" class="heading">{{ heading }}</h2>
    <p v-if="overview" class="overview constrainedWidthText">{{ overview }}</p>
    <div v-if="body" class="body constrainedWidthText">
      <BlockContent :blocks="body" />
    </div>
    <div v-if="cta && cta.text" class="ctas single">
      <CtaLink v-bind="cta" />
    </div>
    <div v-if="ctas && ctas.length == 1" class="ctas single">
      <CtaLink v-bind="ctas[0]" />
    </div>
    <div v-if="ctas && ctas.length > 1" class="ctas multiple">
      <CtaLink v-for="cta in ctas" :key="cta.text" v-bind="cta" class="cta" />
    </div>
  </div>
</template>

<script>
import BlockContent from 'sanity-blocks-vue-component'

export default {
  components: {
    BlockContent,
  },
  props: {
    heading: {
      type: String,
      default: '',
    },
    overview: {
      type: String,
    },
    body: {
      type: Array,
    },
    cta: {
      type: Object,
    },
    ctas: {
      type: Array,
    },
  },
}
</script>

<style scoped>
.SectionTextBlock {
  text-align: center;
}
.heading {
  font-size: var(--font-size-10);
  line-height: 1.3;
}
.heading, .overview {
  font-weight: 600;
  color: var(--text-color-black);
}
.constrainedWidthText {
  max-width: 58rem;
  margin-left: auto;
  margin-right: auto;
}
.body {
  font-weight: 400;
  color: var(--text-color-dark);
}
.overview, .body {
  font-size: var(--font-size-4);
  line-height: 1.7;
}
.heading + .overview {
  margin-top: var(--spacing-0);
}
.heading + .body {
  margin-top: var(--spacing-7);
}
.overview + .body {
  margin-top: var(--spacing-4);
}
.overview + .ctas {
  margin-top: var(--spacing-2);
}
.body + .ctas {
  margin-top: var(--spacing-7);
}
.cta + .cta {
  margin-top: var(--spacing-5);
  display: block;
}

@media (--screen-md) {
  .ctas.multiple {
    max-width: 48rem;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: left;
    gap: var(--spacing-5);
  }
  .cta + .cta {
    margin-top: 0;
  }
}
</style>
