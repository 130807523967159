<template>
  <Container tagName="aside" class="PencilBanner">
    {{ content.message }}
    <UnstyledCta v-if="content.cta" :action="content.cta.action" class="cta">
      {{ content.cta.text }}
    </UnstyledCta>
  </Container>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.PencilBanner {
  padding-top: var(--spacing-5);
  padding-bottom: var(--spacing-5);
  background-color: #6722B0;

  font-weight: 400;
  font-size: var(--font-size-2);
  color: var(--text-color-white);
}

.cta {
  font-weight: 600;
}

.cta:hover {
  text-decoration: underline;
}

@media (--screen-lg) {
  .PencilBanner {
    text-align: center;
  }
}
</style>
