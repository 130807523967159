<template>
  <a class="BlockContentTextLink" :href="href" :target="blank && '_blank'"><slot /></a>
</template>

<script>
export default {
  props: {
    href: String,
    blank: Boolean,
  },
}
</script>

<style scoped>
.BlockContentTextLink {
  text-decoration: underline;
  transition: color 0.15s;
}
.BlockContentTextLink:hover {
  color: var(--text-link-hover-color, var(--brand-color));
}
</style>
