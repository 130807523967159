<template>
  <Container tagName="footer" class="FooterCta">
    <h2 class="title">{{ content.title }}</h2>
    <p class="text">{{ content.text }}</p>
    <CtaButton class="--bright-blue ctaButton" icon="arrow" v-bind="content.cta" />
  </Container>
</template>

<script>
export default {
  props: {
    content: Object,
  },
}
</script>

<style scoped>

.FooterCta {
  padding-top: var(--spacing-21);
  padding-bottom: var(--spacing-21);
  background-color: #0D213A;
  text-align: center;
}

.title {
  color: var(--text-color-white);
  font-weight: 600;
  font-size: var(--font-size-12);
  line-height: 1;
}

.text {
  max-width: 42rem;
  margin-left: auto;
  margin-right: auto;
  color: var(--text-color-light);
  font-weight: normal;
  font-size: var(--font-size-5);
  line-height: 1.25;
}

.title + .text,
.text + .ctaButton {
  margin-top: var(--spacing-10);
}

@media (--screen-lg) {

  .FooterCta {
    padding-top: var(--spacing-17);
    padding-bottom: var(--spacing-16);
  }

  .title + .text,
  .text + .ctaButton {
    margin-top: var(--spacing-8);
  }
}
</style>
