<template>
  <video class="SanityVideo" autoplay loop muted playsinline :poster="posterImageUrl">
    <SanityVideoSource v-if="videoFile" :asset="videoFile.asset" />
  </video>
</template>

<script>
import { sanityImageUrl } from '@inseego-digital/sanity-image-components'

export default {
  props: {
    posterImage: {
      type: Object,
      default: null,
    },
    videoFile: {
      type: Object,
      default: null,
    },
  },
  computed: {
    posterImageUrl() {
      const imageId = this.posterImage?.asset?._ref
      if (imageId) {
        const options = {
          w: '1280',
          auto: 'format',
        }
        return sanityImageUrl(this.$config.sanity, imageId, options)
      }
      return false
    },
  },
}
</script>
