<template>
  <div class="ResourceTile">
    <div class="text">
      <h4 class="title">{{ content.title }}</h4>
      <a v-if="content.downloadText"
        class="downloadLink"
        :href="content.downloadUrl"
        download
        target="_blank"
      >
        {{ content.downloadText}}
        <span v-html="downloadSvg" class="icon" />
      </a>
    </div>
    <div class="imageBox">
      <SanityImage v-if="content.image" :asset="content.image.asset" class="image" />
    </div>
  </div>
</template>

<script>
import downloadSvg from '!raw-loader!@/assets/images/download.svg'

export default {
  props: {
    content: {
      type: Object,
      default: Object,
    },
  },
  data() {
    return {
      downloadSvg
    }
  },
}
</script>

<style scoped>

.ResourceTile {
  border: thin solid #d2d2d2;
  border-radius: 6px;
  padding: var(--spacing-8) var(--spacing-9);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: var(--spacing-3);
}

.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  row-gap: var(--spacing-2);
}

.image {
  width: 100%;
  max-height: 110px;
  height: 100%;
  object-fit: contain;
}

.title {
  font-weight: 600;
  font-size: var(--font-size-3);
  line-height: 1.5;
  color: var(--text-color-black);
}

.downloadLink {
  font-weight: 400;
  font-size: var(--font-size-2);
  color: var(--brand-color);
  white-space: nowrap;
}

.icon >>> svg {
  display: inline-block;
  margin-left: 1em;
}

.ctaWrapper {
  margin-top: var(--spacing-3);
}
</style>
