<template>
  <ColorContext :backgroundColor="backgroundColor" class="BasicContentSection">
    <component :is="contentSectionActualComponent"
      v-bind="{ content }"
      class="contentSectionActual"
    >
      <template #media>
        <AnyMedia class="media" :media="content.media" />
      </template>
    </component>
  </ColorContext>
</template>

<script>
import AnyMedia from '../../elems/AnyMedia.vue'
import ColorContext from '../../elems/ColorContext.vue'
import CenteredContentSection from './CenteredContentSection.vue'
import DoubleColumnContentSection from './DoubleColumnContentSection.vue'
import InlineContentSection from './InlineContentSection.vue'

export default {
  components: {
    AnyMedia,
    ColorContext,
  },
  props: {
    content: Object,
  },
  computed: {
    backgroundColor() {
      return this.content.presentationOptions?.backgroundColor;
    },
    contentSectionActualComponent() {
      switch (this.content.presentationOptions?.layout) {
      case 'centered':
        return CenteredContentSection
      case 'double-column':
        return DoubleColumnContentSection
      default:
        return InlineContentSection
      }
    },
  },
}
</script>

<style scoped>

.contentSectionActual {
  background-color: var(--background-color);
  color: var(--text-color);
  padding-bottom: var(--spacing-15);
  padding-top: var(--spacing-15);
}

.media >>> img,
.media >>> video {
  border-radius: var(--border-radius);
}
</style>
