<template>
  <Container tagName="section" class="TextContentSection">
    <div class="image" v-if="content.image">
      <SanityImage :asset="content.image.asset" />
    </div>
    <div class="text">
      <h2 v-if="content.heading" class="heading">{{ content.heading }}</h2>
      <DefaultBlockContent v-if="content.body" :blocks="content.body" class="body" />
    </div>
  </Container>
</template>

<script>
import DefaultBlockContent from '../elems/DefaultBlockContent.vue'

export default {
  components: {
    DefaultBlockContent,
  },
  props: {
    content: Object,
  },
}
</script>

<style scoped>

.TextContentSection {
  padding-top: var(--spacing-15);
  padding-bottom: var(--spacing-15);
  color: var(--text-color-dark);
}

.image + .text {
  margin-top: var(--spacing-12);
}

.image img {
  border-radius: var(--border-radius);
}

.heading {
  color: var(--text-color-black);
  font-weight: 600;
  line-height: 1.2;
}

.heading + .body {
  margin-top: var(--spacing-7);
}

.heading,
.body >>> h2 {
  font-size: var(--font-size-8);
}

.body >>> h3 {
  font-size: var(--font-size-7);
}

.body >>> h4 {
  font-size: var(--font-size-6);
}

.body >>> h5 {
  font-size: var(--font-size-5);
}

.body >>> h6 {
  font-size: var(--font-size-4);
}

@media (--screen-lg) {

  .image {
    width: 75%;
  }

  .text {
    width: 67%;
  }

  .heading,
  .body >>> h2 {
    font-size: var(--font-size-10);
  }

  .body >>> h3 {
    font-size: var(--font-size-8);
  }

  .body >>> h4 {
    font-size: var(--font-size-7);
  }

  .body >>> h5 {
    font-size: var(--font-size-5);
  }

  .body >>> h6 {
    font-size: var(--font-size-4);
  }
}
</style>
