<template>
  <div class="CenteredTextBox">
    <HeadingChunk :heading="content.heading" :label="content.label" class="headingChunk" />
    <BodyChunk :body="content.body" :cta="content.cta" v-bind="{ ctaOptions }" class="bodyChunk" />
  </div>
</template>

<script>
import BodyChunk from './BodyChunk.vue'
import HeadingChunk from './HeadingChunk.vue'

export default {
  components: {
    BodyChunk,
    HeadingChunk,
  },
  props: {
    content: Object,
  },
  computed: {
    ctaOptions() {
      const { backgroundColor } = this.content.presentationOptions
      return {
        form: 'button',
        colorClass: backgroundColor === 'dark-blue' ? '--bright-blue' : '--dark-blue',
      }
    },
  },
}
</script>

<style scoped>

.headingChunk + .bodyChunk {
  margin-top: var(--spacing-7);
}

@media (--screen-lg) {

  .CenteredTextBox {
    text-align: center;
    margin: 0 auto;
    max-width: 850px;
  }

  .headingChunk {
    --heading-font-size: var(--font-size-9);
  }
}
</style>
