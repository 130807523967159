<template>
  <div class="ProductFeatureFineprint">
    <DefaultBlockContent :blocks="content.body" />
  </div>
</template>

<script>
import DefaultBlockContent from './DefaultBlockContent.vue'

export default {
  components: {
    DefaultBlockContent,
  },
  props: {
    content: Object,
  },
}
</script>

<style scoped>
.ProductFeatureFineprint {
  font-weight: normal;
  font-size: var(--font-size-1);
  font-style: italic;
  line-height: 1.7;
}
.ProductFeatureFineprint * + * {
  margin-top: var(--spacing-6);
}
.ProductFeatureFineprint a {
  text-decoration: underline;
}
.ProductFeatureFineprint a:hover {
  color: var(--brand-color);
}
</style>
