<template>
  <Container tagName="section" class="ProductDocumentationContainer">
    <div class="ProductDocumentation">
      <h2 v-if="content.heading" class="heading">{{ content.heading }}</h2>
      <div class="sections">
        <div class="section">
          <h3 v-if="content.subHeading" class="sectionHeading">{{ content.subHeading }}</h3>
          <div class="sectionContent">
            <div v-if="content.body" class="body">
              <DefaultBlockContent :blocks="content.body" />
            </div>
            <div v-if="content.cta" class="ctaWrapper">
              <CtaLink v-bind="content.cta" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import DefaultBlockContent from '../elems/DefaultBlockContent.vue'

export default {
  components: {
    DefaultBlockContent,
  },
  props: {
    content: Object,
  },
}
</script>

<style scoped>
  .ProductDocumentationContainer {
    background-color: var(--bg-color-pale-gray);
    color: var(--text-color-dark);
  }
  .ProductDocumentation {
    padding-top: var(--spacing-15);
    padding-bottom: var(--spacing-15);
  }
  .heading {
    color: var(--text-color-black);
    font-weight: 600;
    font-size: var(--font-size-8);
    line-height: 1.2;
    margin-bottom: var(--spacing-14);
  }
  .sectionHeading {
    color: var(--text-color-black);
    font-weight: 600;
    font-size: var(--font-size-3);
    line-height: 1.2;
    margin-bottom: var(--spacing-8);
  }
  .body {
    font-weight: normal;
    font-size: var(--font-size-3);
    line-height: 1.7;
  }
  .body * + * {
    margin-top: var(--spacing-6);
  }
  .body + .ctaWrapper {
    margin-top: var(--spacing-8);
  }

  @media (--screen-lg) {
    .section {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      column-gap: 1rem;
    }
    .sectionHeading {
      grid-column: 1 / span 4;
      margin-right: var(--spacing-18);
    }
    .sectionContent {
      grid-column: 5 / span 7;
    }
  }
</style>
