<template>
  <div :class="$style.productCard">
    <div v-if="product.image" :class="$style.image">
      <ScaledImage :asset="product.image.asset" :diagonalLength="300" />
    </div>
    <h3 v-if="product.heading">
      {{ product.heading }}
    </h3>
    <div v-if="product.body" :class="$style.summary">
      <DefaultBlockContent :blocks="product.body" />
    </div>
    <div v-if="product.detailsButtonText" :class="$style.detailsLink">
      <CtaLink @click="openModal()" :text="product.detailsButtonText" />
    </div>
    <CtaButton v-if="product.cta" :class="$style.productCta" class="--bright-blue" v-bind="product.cta" />
    <div v-if="product.detailsBody" v-show="isModalOpen" :class="$style.productModal">
      <div :class="$style.modalPane">
        <img src="../../../assets/images/close.svg" width="24" height="24" :class="$style.closeButton" @click="closeModal()" />
        <ScaledImage v-if="product.image" :class="$style.productImage" :asset="product.image.asset" :diagonalLength="400" />
        <div v-if="product.heading" :class="$style.productTitle">{{ product.heading }}</div>
        <div v-if="product.detailsTitle" :class="$style.detailsTitle">{{ product.detailsTitle }}</div>
        <div
          v-for="{ key, title, blocks } in collatedDetailsBody"
          :key="key"
          :class="$style.productDetails"
        >
          <p :class="$style.title">{{ title }}</p>
          <div :class="$style.detail">
            <DefaultBlockContent :blocks="blocks" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultBlockContent from '../../elems/DefaultBlockContent.vue'
import ScaledImage from '../../elems/ScaledImage.vue'

export default {
  components: {
    DefaultBlockContent,
    ScaledImage,
  },
  props: {
    product: Object,
  },
  data() {
    return {
      isModalOpen: false,
    }
  },
  computed: {
    collatedDetailsBody() {
      var section = null
      const sections = []
      for (let block of this.product.detailsBody) {
        if (block.style === 'h4') {
          section = {
            key: block._key,
            title: block.children.map(({ text }) => text).join(' '),
            blocks: [],
          }
          sections.push(section)
        }
        else if (section) {
          section.blocks.push(block)
        }
      }
      return sections
    },
  },
  methods: {
    openModal() {
      this.$lockScroll(true)
      this.isModalOpen = true
    },
    closeModal(){
      this.$lockScroll(false)
      this.isModalOpen = false
    },
  },
}
</script>

<style module>
.productCard {
  display: flex;
  flex-direction: column;
  background-color: var(--bg-color-white);
  border-radius: var(--border-radius);
  color: var(--text-color-dark);
  padding: var(--spacing-9) var(--spacing-9) var(--spacing-6);
}
.productCard .image {
  display: flex;
  align-items: center;
  height: 18rem;
  margin-bottom: var(--spacing-8);
}
.productCard .image img {
  margin: 0 auto;
  max-height: 100%;
}
.productCard h3 {
  color: var(--text-color-black);
  font-size: var(--font-size-3);
  font-weight: 600;
  line-height: 1.2;
}
.productCard .summary {
  margin-top: var(--spacing-4);
  margin-bottom: var(--spacing-4);
}

.detailsLink {
  margin-top: auto;
  margin-bottom: var(--spacing-9);
}

.productCta {
  margin-top: auto;
  width: 100%;
}

.productModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, .75);
  z-index: 50;
}

.modalPane  {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 48rem;
  background-color: var(--bg-color-white);
  vertical-align: middle;
  padding: var(--spacing-10);
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  margin-top: var(--spacing-10);
  margin-right: var(--spacing-10);
}

.productImage {
  margin: 0 auto var(--spacing-8);
}

.productTitle {
  margin: 0 auto;
  font-size: var(--font-size-5);
  font-weight: 600;
  text-align: center;
  max-width: 24rem;
}

.detailsTitle {
  font-size: var(--font-size-4);
  font-weight: 600;
  margin-top: var(--spacing-10);
  margin-bottom: var(--spacing-10);
  padding-top: var(--spacing-10);
  border-top: 1px solid var(--hairline-color);
}

.productDetails {
  display: grid;
  gap: var(--spacing-0);
  margin-top: var(--spacing-8);
}
.productDetails .title {
  grid-column: span 1 / span 1;
  font-weight: 600;
}
.productDetails .detail {
  grid-column: span 1 / span 1;
}
.productDetails .detail * + * {
  margin-top: var(--spacing-6);
}

@media (--screen-lg) {
  .productModal .modalPane  {
    margin: var(--spacing-10) auto;
    border-radius: var(--border-radius);
  }
  .productDetails {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: var(--spacing-10);
  }
  .productDetails .title {
    grid-column: span 4 / span 4;
  }
  .productDetails .detail {
    grid-column: span 7 / span 7;
  }
  .closeButton {
    margin-top: var(--spacing-10);
    margin-right: var(--spacing-10);
  }
}
</style>
