<template>
  <Container tagName="section" class="ProductHowToBuyContainer">
    <div class="ProductHowToBuy">
      <h2 v-if="content.heading" class="heading">{{ content.heading }}</h2>
      <div class="sections">
        <div v-if="content.hasBusiness" class="section">
          <h3 v-if="content.businessHeading" class="sectionHeading">{{ content.businessHeading }}</h3>
          <div class="sectionContent">
            <div v-if="content.businessBody" class="body">
              <DefaultBlockContent :blocks="content.businessBody" />
            </div>
            <div v-if="content.businessCta" class="ctaWrapper">
              <CtaButton class="--dark-blue" icon="arrow" v-bind="content.businessCta" />
            </div>
          </div>
        </div>
        <div v-if="content.hasPersonal" class="section">
          <h3 v-if="content.personalHeading" class="sectionHeading">{{ content.personalHeading }}</h3>
          <div class="sectionContent">
            <div v-if="content.personalBody" class="body">
              <DefaultBlockContent :blocks="content.personalBody" />
            </div>
            <div class="logoGrid">
              <div v-for="item in content.carriers" :key="item._key" class="logoFrame">
                <a v-if="item.url" :href="item.url" target="_blank"><SanityImage :asset="item.logo.asset" :alt="item.title" /></a>
                <SanityImage v-else :asset="item.logo.asset" :alt="item.title" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import DefaultBlockContent from '../elems/DefaultBlockContent.vue'

export default {
  components: {
    DefaultBlockContent,
  },
  props: {
    content: Object,
  },
}
</script>

<style scoped>
  .ProductHowToBuyContainer {
    background-color: var(--bg-color-pale-gray);
    color: var(--text-color-dark);
  }
  .ProductHowToBuy {
    padding-top: var(--spacing-15);
    padding-bottom: var(--spacing-15);
  }
  .heading {
    color: var(--text-color-black);
    font-weight: 600;
    font-size: var(--font-size-8);
    line-height: 1.2;
    margin-bottom: var(--spacing-14);
  }
  .section + .section {
    margin-top: var(--spacing-14);
  }
  .sectionHeading {
    color: var(--text-color-black);
    font-weight: 600;
    font-size: var(--font-size-3);
    line-height: 1.2;
    margin-bottom: var(--spacing-8);
  }
  .body {
    font-weight: normal;
    font-size: var(--font-size-3);
    line-height: 1.7;
  }
  .body >>> p + p {
    margin-top: var(--spacing-6);
  }
  .body + .ctaWrapper {
    margin-top: var(--spacing-8);
  }
  .logoGrid {
    margin-top: var(--spacing-10);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-5);
  }
  .logoFrame {
    border: 1px solid #EEEEEE;
    border-radius: 5px;
    overflow: hidden;
  }

  @media (--screen-md) {
    .logoGrid {
      grid-template-columns: repeat(3, 1fr);
    }
    .logoFrame {
      border-radius: 10px;
    }
  }

  @media (--screen-lg) {
    .section {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      column-gap: 1rem;
    }
    .sectionHeading {
      grid-column: 1 / span 4;
      margin-right: var(--spacing-18);
    }
    .sectionContent {
      grid-column: 5 / span 7;
    }
    .logoGrid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
</style>
