<template>
  <div class="ProductFeature">
    <h3 v-if="content.heading">{{ content.heading }}:</h3>
    <div class="featureContent">
      <div v-if="content.image" class="featureImage">
        <SanityImage :asset="content.image.asset" />
      </div>
      <div v-if="content.body" class="featureBody">
        <DefaultBlockContent :blocks="content.body" />
      </div>
    </div>
  </div>
</template>

<script>
import DefaultBlockContent from './DefaultBlockContent.vue'

export default {
  components: {
    DefaultBlockContent,
  },
  props: {
    content: Object,
  },
}
</script>

<style scoped>
.ProductFeature h3 {
  color: var(--text-color-black);
  font-weight: 600;
  font-size: var(--font-size-3);
  line-height: 1.7;
  margin-bottom: var(--spacing-8);
}
.featureImage + .featureBody {
  margin-top: var(--spacing-6);
}
.featureBody {
  font-weight: normal;
  font-size: var(--font-size-3);
  line-height: 1.7;
}
.featureBody >>> h4 {
  font-weight: 600;
}
.featureBody >>> p + p,
.featureBody >>> p + h4,
.featureBody >>> ul li + li {
  margin-top: var(--spacing-6);
}
.featureBody >>> a {
  text-decoration: underline;
}
.featureBody >>> a:hover {
  color: var(--brand-color);
}

@media (--screen-lg) {
  .ProductFeature {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 1rem;
  }
  .ProductFeature h3 {
    grid-column: 1 / span 4;
    margin-right: var(--spacing-18);
    margin-bottom: 0;
  }
  .featureContent {
    grid-column: 5 / span 8;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 1rem;
  }
  .featureImage {
    grid-column: 1 / span 5;
  }
  .featureImage + .featureBody {
    grid-column: 7 / span 5;
    margin-top: 0;
  }
  .featureBody {
    grid-column: span 12 / span 12;
  }
}
</style>
