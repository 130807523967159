<template>
  <main>
    <HeroWithTextOnly v-if="content.hero" :breadcrumbs="breadcrumbs" :content="content.hero" />
    <SupportOverviewSection :content="content.overviewSection" />
    <FaqSection :content="content.faqSection" class="--no-border" :id="content.faqSection.sectionId" />
    <SupportFooterSection :content="content.footerSection" />
  </main>
</template>

<script>
import FaqSection from '../bodySections/FaqSection.vue'
import HeroWithTextOnly from '../headerFooterSections/HeroWithTextOnly.vue'
import SupportFooterSection from './SupportFooterSection.vue'
import SupportOverviewSection from './SupportOverviewSection.vue'

export default {
  components: {
    FaqSection,
    HeroWithTextOnly,
    SupportFooterSection,
    SupportOverviewSection,
  },
  props: {
    breadcrumbs: Array,
    content: Object,
  },
  provide() {
    return {
      locale: () => this.content?.locale,
    }
  },
  contentQuery: `
    *[_id == $id][0]{
      locale,
      hero,
      overviewSection,
      faqSection,
      footerSection,
    }
  `,
}
</script>
