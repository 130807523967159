<template>
  <ColorContext :backgroundColor="backgroundColor">
    <Container
      tagName="section"
      class="ProductFeatureCards"
    >
      <CenteredTextBox v-bind="{ content }" />
      <div v-if="content.productCards"
        :style="{ '--cards-per-row': content.productCards.length }"
        class="cardsLineup"
      >
        <ProductFeatureCard
          v-for="product in content.productCards"
          :key="product._key"
          class="productCard"
          :content="product"
        />
      </div>
    </Container>
  </ColorContext>
</template>

<script>
import CenteredTextBox from '../blocks/CenteredTextBox.vue'
import ColorContext from '../../elems/ColorContext.vue';
import ProductFeatureCard from './ProductFeatureCard.vue'

export default {
  name: 'ProductFeatureCards',
  components: {
    CenteredTextBox,
    ColorContext,
    ProductFeatureCard,
  },
  props: {
    content: Object,
  },
  computed: {
    backgroundColor() {
      return this.content.presentationOptions?.backgroundColor;
    },
  },
}
</script>

<style scoped>

.ProductFeatureCards {
  background-color: var(--background-color);
  padding-top: var(--spacing-15);
  padding-bottom: var(--spacing-15);
}

.cardsLineup {
  display: grid;
  gap: var(--spacing-10);
  margin-top: var(--spacing-12);
}

@media (--screen-sm) {

  .cardsLineup {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (--screen-lg) {

  .cardsLineup {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .productCard {
    flex-basis: calc(100% / var(--cards-per-row));
    max-width: 36rem;
  }
}
</style>
