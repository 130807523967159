<template>
  <div :class="backgroundColor">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    backgroundColor: {
      type: String,
      default: 'white',
    },
  },
}
</script>

<style scoped>
.dark-blue {
  --background-color: var(--bg-color-dark-blue);
  --bullet-image: url('../../assets/images/bullet-light.svg');
  --heading-color: var(--text-color-white);
  --tab-color-active: var(--brand-color);
  --tab-color: #90AFCE;
  --text-color: var(--text-color-light);
  --text-link-hover-color: var(--brand-color);
}
.brand-blue {
  --background-color: var(--bg-color-brand-blue);
  --bullet-image: url('../../assets/images/bullet-dark.svg');
  --heading-color: var(--text-color-white);
  --tab-color-active: var(--text-color-white);
  --tab-color: #B5E1FF;
  --text-color: var(--text-color-white);
  --text-link-hover-color: #08192E;
}
.pale-blue {
  --background-color: var(--bg-color-pale-blue);
  --bullet-image: url('../../assets/images/bullet-blue.svg');
  --heading-color: var(--text-color-black);
  --tab-color-active: var(--text-color-black);
  --tab-color: #90AFCE;
  --text-color: var(--text-color-dark);
  --text-link-hover-color: var(--brand-color);
}
.pale-gray {
  --background-color: var(--bg-color-pale-gray);
  --bullet-image: url('../../assets/images/bullet-blue.svg');
  --heading-color: var(--text-color-black);
  --tab-color-active: var(--text-color-black);
  --tab-color: #90AFCE;
  --text-color: var(--text-color-dark);
  --text-link-hover-color: var(--brand-color);
}
.white {
  --background-color: var(--bg-color-white);
  --bullet-image: url('../../assets/images/bullet-blue.svg');
  --heading-color: var(--text-color-black);
  --tab-color-active: var(--text-color-black);
  --tab-color: #90AFCE;
  --text-color: var(--text-color-dark);
  --text-link-hover-color: var(--brand-color);
}
</style>
