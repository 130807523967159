<template>
  <div class="BlockContentCtaButton">
    <CtaButton class="--dark-blue" v-bind="{ text, action }" />
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    action: String,
  },
}
</script>

<style scoped>

.BlockContentCtaButton {
  margin-top: var(--spacing-5);
}

/* Overrides for the default text link styles provided by DefaultBlockContent */

.DefaultBlockContent .BlockContentCtaButton a {
  text-decoration: none;
}

.DefaultBlockContent .BlockContentCtaButton a:hover {
  color: var(--text-color-white);
}
</style>
