<template>
  <section class="SectionGroup">
    <details @toggle="updateIsOpen" class="details">
      <Container tagName="summary" class="summary">
        <header class="header">
          <h2 class="heading">{{ heading }}</h2>
          <ExpandPlusMinus :expanded="!isOpen" class="icon" vStrokeClass="iconVStroke" />
        </header>
      </Container>
      <slot />
    </details>
  </section>
</template>

<script>
import ExpandPlusMinus from '../icons/ExpandPlusMinus.vue'

export default {
  components: {
    ExpandPlusMinus,
  },
  props: {
    heading: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    updateIsOpen(ev) {
      this.isOpen = ev.target.open;
    }
  }
}
</script>

<style scoped>

.SectionGroup {
  padding-bottom: var(--spacing-12);
}

.summary {
  cursor: pointer;
}

.summary:focus {
  outline-style: none;  /* Don't show focus ring on Safari */
}

.header {
  border-top: thin solid var(--hairline-color);
  padding-top: var(--spacing-8);
  padding-bottom: var(--spacing-4);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: var(--font-size-9);  /* Note that this determines the size of both the heading and the '+' icon */
}

.icon {
  /* The icon is square, but this centers it vertically in a box the height of the heading line height */
  height: 1.3em;
  flex: 0 0 0.65em;
}

.details[open] >>> .iconVStroke {
  visibility: hidden;
}

.heading {
  font-weight: 600;
  line-height: 1.3;
  color: var(--text-color-black);
}

@media (--screen-lg) {

  .header {
    font-size: var(--font-size-10);
  }
}
</style>
