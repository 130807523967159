<template>
  <Container tagName="section" class="DoubleColumnContentSection">
    <div class="mediaChunk">
      <slot name="media" />
    </div>
    <DoubleColumnTextBox class="textBox" v-bind="{ content }" />
  </Container>
</template>

<script>
import DoubleColumnTextBox from '../blocks/DoubleColumnTextBox.vue'

export default {
  components: {
    DoubleColumnTextBox,
  },
  props: {
    content: Object,
  },
}
</script>

<style scoped>

.mediaChunk + .textBox {
  margin-top: var(--spacing-10);
}
</style>
