<template>
  <div class="AnyMedia" v-if="media">
    <SanityPicture v-if="media.kind == 'picture'" v-bind="media.picture" />
    <SanityVideo v-else-if="media.kind == 'video'" v-bind="media.video" />
    <ExternalVideo v-else-if="media.kind == 'external-video'" v-bind="media.externalVideo" />
  </div>
</template>

<script>
import ExternalVideo from './ExternalVideo.vue'
import SanityPicture from './SanityPicture.vue'
import SanityVideo from './SanityVideo.vue'

export default {
  components: {
    ExternalVideo,
    SanityPicture,
    SanityVideo,
  },
  props: {
    media: Object,
  },
}
</script>
