<template>
  <main>
    <PencilBanner v-if="content.pencilBanner" :content="content.pencilBanner" />
    <component
      v-if="content.hero"
      :is="heroComponent"
      :content="content.hero"
      :breadcrumbs="breadcrumbs"
    />
    <PageNav v-if="pageNavEntries" :entries="pageNavEntries" :content="content.pageNav" />
    <component
      v-for="sectionContent in content.bodySections || []"
      :key="sectionContent._key"
      :is="bodySectionComponent(sectionContent._type)"
      :content="sectionContent"
      :id="sectionId(sectionContent)"
    />
    <FooterCta v-if="content.footerCta" :content="content.footerCta" />
  </main>
</template>

<script>
import toSlug from '../lib/to-slug.js'

import FaqSection from './bodySections/FaqSection.vue'
import PlaceholderSection from './bodySections/PlaceholderSection.vue'
import PopupTrigger from './bodySections/PopupTrigger/index.vue'
import ProductFeatures from './bodySections/ProductFeatures.vue'

import FooterCta from './headerFooterSections/FooterCta.vue'
import HeroWithBackgroundImage from './headerFooterSections/HeroWithBackgroundImage.vue'
import HeroWithInlineMedia from './headerFooterSections/HeroWithInlineMedia.vue'
import PageNav from './headerFooterSections/PageNav.vue'
import PencilBanner from './headerFooterSections/PencilBanner.vue'

import ProductDocumentation from './productPageSections/ProductDocumentation.vue'
import ProductHowToBuy from './productPageSections/ProductHowToBuy.vue'

export default {
  components: {
    FaqSection,
    FooterCta,
    PageNav,
    PencilBanner,
    PlaceholderSection,
    ProductDocumentation,
    ProductFeatures,
    ProductHowToBuy,
  },
  props: {
    breadcrumbs: Array,
    content: Object,
  },
  computed: {
    heroComponent() {
      switch (this.content?.hero?.presentationOptions?.layout) {
      case 'heroWithBackgroundImage':
        return HeroWithBackgroundImage
      case 'heroWithInlineMedia':
      default:
        return HeroWithInlineMedia
      }
    },
    pageNavEntries() {
      let pageNavEntries = (this.content.bodySections || [])
        .filter(({ navEntry }) => navEntry?.showInNav)
        .map(({ _key, navEntry }) => ({
          _key,
          title: navEntry.title,
          targetId: toSlug(navEntry.title),
        }))
      return pageNavEntries.length > 0 ? pageNavEntries : false
    },
  },
  provide() {
    return {
      locale: () => this.content?.locale,
    }
  },
  methods: {
    bodySectionComponent(type) {
      switch (type) {
      case 'faqSection':
        return FaqSection
      case 'popupTrigger':
        return PopupTrigger
      case 'productDocumentation':
        return ProductDocumentation
      case 'productFeatures':
        return ProductFeatures
      case 'productHowToBuy':
        return ProductHowToBuy
      default:
        return PlaceholderSection
      }
    },
    sectionId({ navEntry }) {
      if (navEntry?.showInNav) {
        return toSlug(navEntry.title)
      }
    },
    sectionHasSeparator({ presentationOptions }) {
      return presentationOptions?.hasSeparator || false
    }
  },
  contentQuery: `
    *[_id == $id][0]{
      locale,
      brand,
      pencilBanner->{ message, cta },
      hero,
      pageNav,
      bodySections,
      footerCta->{ title, text, cta },
    }
  `,
}
</script>

<style scoped>
.sectionWithSeparator {
  border-top: thin solid var(--hairline-color);
}
</style>
