<template>
  <div>
    <div class="leaderCard" @click="openModal()">
      <div class="image">
        <SanityImage :asset="leader.image.asset" />
      </div>
      <h3>{{ leader.name }}</h3>
      <h4>{{ leader.designation }}</h4>
    </div>
    <div v-show="isModalOpen" class="leaderModal">
      <div class="modalPane">
        <img src="../../assets/images/close.svg" width="24" height="24" class="closeButton" @click="closeModal()" />
        <h3>{{ leader.name }}</h3>
        <h4>{{ leader.designation }}</h4>
        <div v-if="leader.bio" class="leaderBio">
          <div class="image">
            <SanityImage :asset="leader.image.asset" />
          </div>
          <DefaultBlockContent :blocks="leader.bio" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultBlockContent from '../elems/DefaultBlockContent.vue'

export default {
  components: {
    DefaultBlockContent,
  },
  props: {
    leader: Object,
  },
  data() {
    return {
      isModalOpen: false,
    }
  },
  methods: {
    openModal() {
      this.$lockScroll(true)
      this.isModalOpen = true
    },
    closeModal(){
      this.$lockScroll(false)
      this.isModalOpen = false
    },
  },
}
</script>

<style scoped>
.leaderCard {
  cursor: pointer;
}
.leaderCard .image img {
  width: 100%;
  height: auto;
  border-radius: var(--border-radius);
}
.leaderCard h3 {
  font-weight: 600;
  font-size: var(--font-size-3);
  line-height: 1;
  text-transform: uppercase;
  color: var(--brand-color);
  margin-top: var(--spacing-4);
}
.leaderCard h4 {
  font-weight: normal;
  font-size: var(--font-size-3);
  line-height: 1.7;
  margin-top: var(--spacing-0);
}

.leaderModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, .75);
  z-index: 50;
}
.leaderModal h3 {
  font-weight: 600;
  font-size: var(--font-size-6);
  line-height: 1;
  text-transform: uppercase;
  color: var(--brand-color);
}
.leaderModal h4 {
  font-weight: normal;
  font-size: var(--font-size-4);
  line-height: 1.7;
  margin-top: var(--spacing-0);
}
.leaderBio {
  font-weight: normal;
  font-size: var(--font-size-3);
  line-height: 1.7;
  margin-top: var(--spacing-6);
}
.leaderBio * + * {
  margin-top: var(--spacing-6);
}
.leaderBio a {
  text-decoration: underline;
}
.leaderBio a:hover {
  color: var(--brand-color);
}
.leaderBio .image img {
  max-width: 100%;
  border-radius: var(--border-radius);
  margin-bottom: var(--spacing-10);
}

.modalPane  {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 64rem;
  background-color: var(--bg-color-white);
  vertical-align: middle;
  padding: var(--spacing-10);
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  margin-top: var(--spacing-10);
  margin-right: var(--spacing-10);
}

@media (--screen-lg) {
  .leaderModal .modalPane  {
    margin: var(--spacing-10) auto;
    border-radius: var(--border-radius);
  }
  .closeButton {
    margin-top: var(--spacing-10);
    margin-right: var(--spacing-10);
  }
  .leaderBio .image img {
    float: right;
    max-width: 366px;
    margin-left: var(--spacing-12);
  }
}
</style>
