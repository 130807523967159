<template>
  <HorizontalScroller>
    <LogoStrip :class="$style.logoStrip" ref="logoStrip" v-bind="{ content, style }" />
  </HorizontalScroller>
</template>

<script>
import HorizontalScroller from './HorizontalScroller.vue'
import LogoStrip from './LogoStrip.vue'

export default {
  components: {
    HorizontalScroller,
    LogoStrip,
  },
  props: {
    content: Object,
  },
  computed: {
    style() {
      return {
        '--count': this.content.logos.length,
      }
    }
  },
}
</script>

<style module>
.logoStrip {
  flex-wrap: nowrap;
  justify-content: space-between;
  width: calc(var(--count) * 142px);
  margin: 0 auto;
}

@media (--screen-md) {
  .logoStrip {
    width: calc(var(--count) * 174px);
  }
}

@media (--screen-lg) {
  .logoStrip {
    width: calc(var(--count) * 252px);
  }
}
</style>
