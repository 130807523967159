<template>
  <div class="HeadingChunk">
    <h2 v-if="label" class="label">{{ label }}</h2>
    <component v-if="heading" :is="label ? 'h3' : 'h2'" class="heading">{{ heading }}</component>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
  }
}
</script>

<style scoped>

.label {
  font-weight: 600;
  font-size: var(--font-size-1);
  line-height: 1;
  text-transform: uppercase;
  color: var(--brand-color);
}

.heading {
  color: var(--heading-color, var(--text-color-black));
  font-size: var(--heading-font-size, var(--font-size-8));
  font-weight: 600;
  line-height: 1.2;
}

.label + .heading {
  margin-top: var(--spacing-3);
}
</style>
