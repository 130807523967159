<template>
  <a class="ActionLink" @click="$pageAction(action, text)"><slot/></a>
</template>

<script>
export default {
  props: {
    action: String,
  },
  computed: {
    text() {
      function getTextContent({ children, text }) {
        if (children) {
          return children.map(getTextContent).join('')
        }
        if (text) {
          return text
        }
        return ''
      }
      return getTextContent({ children: this.$slots.default })
    }
  }
}
</script>

<style scoped>
.ActionLink {
  cursor: pointer;
}
</style>
