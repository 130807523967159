<template>
  <div class="PopupMultiCta">
    <div class="header">
      <h3 class="prompt">{{ content.prompt }}</h3>
      <p class="message">{{ content.message }}</p>
    </div>
    <div class="ctas" v-if="content.ctas">
      <div v-for="cta in content.ctas" @click="ctaClicked()" class="ctaWrapper">
        <SmallCtaButton v-bind="cta" class="cta --dark-blue" />
      </div>
    </div>
    <CloseButton class="closeButton" @click="hidePopup()" />
  </div>
</template>

<script>
import CloseButton from '../../elems/CloseButton.vue'

export default {
  components: {
    CloseButton,
  },
  props: {
    content: Object,
    hidePopup: Function,
    ctaClicked: Function,
  },
}
</script>

<style scoped>

.PopupMultiCta {
  width: 25.75rem;
}

.header {
  padding-top: var(--spacing-11);
  padding-right: var(--spacing-14);
  padding-bottom: var(--spacing-8);
  padding-left: var(--spacing-8);
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: var(--brand-color);
}

.ctas {
  padding: var(--spacing-8);
  background-color: var(--bg-color-white);
  border-width: thin;
  border-style: none solid;
  border-color: var(--hairline-color);
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-5);
}

.closeButton {
  position: absolute;
  top: var(--spacing-8);
  right: var(--spacing-8);
  cursor: pointer;
  color: var(--text-color-white);
}

.prompt {
  font-weight: 600;
  font-size: var(--font-size-4);
  color: var(--text-color-white);
}

.message {
  max-width: 21rem;
  font-weight: 500;
  font-size: var(--font-size-3);
  color: var(--text-color-white);
}

.prompt + .message {
  margin-top: var(--spacing-6);
}

.cta {
  display: block;
}
</style>
