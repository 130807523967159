<template>
  <main class="WhitepaperLandingPage">
    <HeroWithInlineMedia v-if="content.hero" :content="content.hero" />
    <ContentSection
      v-for="sectionContent in content.bodySections || []"
      :key="sectionContent._key"
      :content="sectionContent"
      :class="sectionHasSeparator(sectionContent) && 'sectionWithSeparator'"
    />
    <InPageForm
      :id="content.inPageForm.sectionId"
      :content="content.inPageForm"
      :whenSubmitted="redirectToWhitepaper"
    />
  </main>
</template>

<script>
import ContentSection from './ContentSection'
import InPageForm from '../bodySections/InPageForm/index'
import HeroWithInlineMedia from '../headerFooterSections/HeroWithInlineMedia'

export default {
  components: {
    ContentSection,
    HeroWithInlineMedia,
    InPageForm,
  },
  props: {
    content: Object,
  },
  provide() {
    return {
      locale: () => this.content?.locale,
    }
  },
  methods: {
    sectionHasSeparator({ presentationOptions }) {
      return presentationOptions?.hasSeparator || false
    },
    redirectToWhitepaper() {
      window.location = this.content.whitepaperUrl
    },
  },
  contentQuery: `
    *[_id == $id][0]{
      locale,
      title,
      hero,
      bodySections,
      inPageForm,
      whitepaperUrl,
    }
  `,
}
</script>

<style scoped>
.sectionWithSeparator {
  border-top: thin solid var(--hairline-color);
}
</style>
