<template>
  <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="currentColor" stroke-width="3">
      <line x1="0" x2="18" y1="9" y2="9" />
      <line x1="9" x2="9" y1="0" y2="18" :class="vStrokeClass"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    vStrokeClass: String,
  }
}
</script>
