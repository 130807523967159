<template>
  <main>
    <PencilBanner v-if="content.pencilBanner" :content="content.pencilBanner" />
    <component
      v-if="content.hero"
      :is="heroComponent"
      :content="content.hero"
      :breadcrumbs="breadcrumbs"
    />
    <PageNav v-if="pageNavEntries" :entries="pageNavEntries" :content="content.pageNav" />
    <AutoBodySection
      v-for="sectionContent in content.bodySections || []"
      :key="sectionContent._key"
      :content="sectionContent"
      :id="bodySectionId(sectionContent)"
      class="bodySection"
      :class="sectionHasSeparator(sectionContent) && 'sectionWithSeparator'"
    />
    <FooterCta v-if="content.footerCta" :content="content.footerCta" />
  </main>
</template>

<script>
import toSlug from '../lib/to-slug.js'

import AutoBodySection from './bodySections/AutoBodySection.vue'
import FooterCta from './headerFooterSections/FooterCta.vue'
import PageNav from './headerFooterSections/PageNav.vue'
import PencilBanner from './headerFooterSections/PencilBanner.vue'

import HeroWithBackgroundImage from './headerFooterSections/HeroWithBackgroundImage.vue'
import HeroWithInlineMedia from './headerFooterSections/HeroWithInlineMedia.vue'
import HeroWithTextOnly from './headerFooterSections/HeroWithTextOnly.vue'

function bodySectionId({ sectionId, navEntry }) {
  if (sectionId) {
    return sectionId
  }
  if (navEntry?.id) {
    return navEntry.id
  }
  if (navEntry?.title) {
    return toSlug(navEntry.title)
  }
}

export default {
  components: {
    AutoBodySection,
    FooterCta,
    PageNav,
    PencilBanner,
  },
  props: {
    breadcrumbs: Array,
    content: Object,
  },
  computed: {
    heroComponent() {
      switch (this.content?.hero?.presentationOptions?.layout) {
      case 'heroWithBackgroundImage':
        return HeroWithBackgroundImage
      case 'heroWithTextOnly':
        return HeroWithTextOnly
      case 'heroWithInlineMedia':
      default:
        return HeroWithInlineMedia
      }
    },
    pageNavEntries() {
      const pageNavEntries = (this.content.bodySections || [])
        .filter(({ navEntry }) => navEntry?.showInNav && navEntry.title)
        .map((sectionContent) => ({
          _key: sectionContent._key,
          title: sectionContent.navEntry.title,
          targetId: bodySectionId(sectionContent),
        }))
      return pageNavEntries.length > 0 ? pageNavEntries : false
    },
  },
  provide() {
    return {
      locale: () => this.content?.locale,
    }
  },
  methods: {
    bodySectionId,
    sectionHasSeparator({ presentationOptions }) {
      return presentationOptions?.hasSeparator || false
    }
  },
  contentQuery: `
    *[_id == $id][0]{
      locale,
      brand,
      pencilBanner->{ message, cta },
      hero,
      pageNav,
      bodySections,
      footerCta->{ title, text, cta },
    }
  `,
}
</script>

<style scoped>
.sectionWithSeparator {
  border-top: thin solid var(--hairline-color);
}
.bodySection {
  scroll-margin-top: 4rem;  /* Ensure title isn't covered by sticky-nav */
}
</style>
