<template>
  <header class="HomepageHero">
    <div class="mainContent">
      <div class="textPane">
        <h1 class="heading">{{ content.heading }}</h1>
        <p class="overview">{{ content.overview }}</p>
        <div class="cta">
          <LargeCtaButton v-bind="content.cta" icon="arrow" class="ctaButton --dark-blue" />
        </div>
      </div>
      <div class="mediaPane">
        <SanityVideo :videoFile="content.video" :posterImage="content.videoPlaceholder" class="video" />
      </div>
    </div>
    <Container class="logoStripWrapper">
      <ScrollableLogoStrip :content="content.logoStrip" />
    </Container>
  </header>
</template>

<script>
import ScrollableLogoStrip from '../../elems/ScrollableLogoStrip.vue'
import SanityVideo from '../../elems/SanityVideo.vue'

export default {
  components: {
    ScrollableLogoStrip,
    SanityVideo,
  },
  props: {
    content: Object,
  },
}
</script>

<style scoped>
.textPane {
  background-color: var(--bg-color-white);
  padding: var(--spacing-17) var(--gutter-width) var(--spacing-17);
}

.mediaPane {
  background-color: var(--bg-color-dark-blue);
}

.heading {
  font-weight: 600;
  font-size: var(--font-size-12);
  line-height: 1.05;
  color: var(--text-color-black);
}

.overview {
  font-weight: 400;
  font-size: var(--font-size-4);
  line-height: 1.75;
  max-width: 32rem;
  color: var(--text-color-dark);
}

.heading + .overview {
  margin-top: var(--spacing-7);
}

.overview + .cta {
  margin-top: var(--spacing-8);
}

.logoStripWrapper {
  border-top: 1px solid var(--hairline-color);
  border-bottom: 1px solid var(--hairline-color);
}

@media (--screen-lg) {
  .mainContent {
    display: flex;
  }

  .textPane, .mediaPane {
    flex-basis: 50%;
  }

  .textPane {
    padding-right: var(--spacing-10);
  }

  .video {
    object-position: center;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
</style>
