<template>
  <ColorContext class="Card" backgroundColor="white">
    <div v-if="content.image" class="image">
      <ScaledImage :asset="content.image.asset" :diagonalLength="300" />
    </div>
    <h3 v-if="content.heading" class="heading">{{ content.heading }}</h3>
    <DefaultBlockContent v-if="content.body" :blocks="content.body" class="body" />
    <CtaLink v-if="content.cta" class="cta" v-bind="content.cta" />
  </ColorContext>
</template>

<script>
import ColorContext from '../../elems/ColorContext.vue'
import DefaultBlockContent from '../../elems/DefaultBlockContent.vue'
import ScaledImage from '../../elems/ScaledImage.vue'

export default {
  components: {
    ColorContext,
    DefaultBlockContent,
    ScaledImage,
  },
  props: {
    content: {
      type: Object,
      default: Object,
    },
  },
}
</script>

<style scoped>

.Card {
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  border-radius: var(--border-radius);
  padding: var(--spacing-9);
  text-align: center;
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--spacing-10);
}

.heading {
  color: var(--text-color-black);
  font-size: var(--font-size-3);
  font-weight: 600;
  line-height: 1.2;
}

.body {
  margin-top: var(--spacing-6);
}

.cta {
  margin-top: var(--spacing-4);
}
</style>
