<template>
  <div>
    <header class="header">
      <label>
        Locale:
        <select v-model="selectedLocale">
          <option v-for="locale in bodySectionLocales" :key="locale">{{locale}}</option>
        </select>
      </label>
      <label>
        Type:
        <select v-model="selectedType">
          <option v-for="type in bodySectionTypes.sort()" :key="type">{{type}}</option>
        </select>
      </label>
    </header>
    <BodySectionExample
      v-for="bodySection, index in selectedBodySections"
      :key="index"
      :content="bodySection"
    />
  </div>
</template>

<script>
import BodySectionExample from './components/BodySectionExample.vue'

function unique(value, index, self) {
  return self.indexOf(value) === index;
}

export default {
  components: {
    BodySectionExample,
  },
  props: {
    documentsWithBodySections: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      selectedLocale: 'en-US',
      selectedType: 'polymorphicSection',
    }
  },
  computed: {
    bodySections() {
      return this.documentsWithBodySections.flatMap((document) => {
        return (
          document.bodySections
            .filter(({ _type }) => _type !== 'reference')
            .map((bodySection) => ({
              _docId: document._id,
              _docType: document._type,
              _docTitle: document.title,
              locale: document.locale,
              ...bodySection,
            }))
        )
      })
    },
    bodySectionLocales() {
      return this.bodySections.map(({ locale }) => locale).filter(unique)
    },
    bodySectionTypes() {
      return this.bodySections.map(({ _type }) => _type).filter(unique)
    },
    selectedBodySections() {
      return (
        this.selectedType
          ? this.bodySections.filter(({ _type, locale }) => (
            _type === this.selectedType && locale === this.selectedLocale
          ))
          : []
      )
    }
  },
}
</script>

<style scoped>
.header {
  border-top: thin solid var(--hairline-color);
  padding: var(--spacing-3);
}
</style>
