<template>
  <div class="CtaBlock">
    <h3 class="title">{{ text }}</h3>
    <CtaButton v-if="cta" v-bind="cta" icon="arrow" class="cta --dark-blue" />
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    cta: {
      type: Object,
    },
  },
}
</script>

<style scoped>

.title {
  font-weight: 600;
  font-size: var(--font-size-8);
  line-height: 1.3;
  color: var(--text-color-black);
}
.title + .cta {
  margin-top: var(--spacing-8);
}

@media (--screen-lg) {
  .CtaBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: var(--spacing-4);
  }
  .title {
    font-size: 2.5rem;
    max-width: 14em;
  }
  .cta {
    flex-shrink: 0;
  }
  .title + .cta {
    margin-top: 0;
  }
}
</style>
