<template>
  <div class="DoubleColumnTextBox">
    <HeadingChunk :heading="content.heading" :label="content.label" class="headingChunk" />
    <BodyChunk :body="content.body" :cta="content.cta" class="bodyChunk" />
  </div>
</template>

<script>
import BodyChunk from './BodyChunk.vue'
import HeadingChunk from './HeadingChunk.vue'

export default {
  components: {
    BodyChunk,
    HeadingChunk,
  },
  props: {
    content: Object,
  },
}
</script>

<style scoped>

.headingChunk + .bodyChunk {
  margin-top: var(--spacing-7);
}

@media (--screen-lg) {

  .headingChunk + .bodyChunk {
    margin-top: 0;
  }

  .DoubleColumnTextBox {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: var(--spacing-6);
  }

  .headingChunk,
  .bodyChunk {
    max-width: 36rem;
  }
}
</style>
