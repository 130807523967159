<template>
  <main>
    <PencilBanner v-if="content.pencilBanner" :content="content.pencilBanner" />
    <HomepageHero :content="content.hero" />
    <component
      v-for="sectionContent in bodySectionContents"
      :key="sectionContent._key"
      :is="bodySectionComponent(sectionContent._type)"
      :id="sectionContent.sectionId"
      :content="sectionContent"
    />
    <FooterCta v-if="content.footerCta" :content="content.footerCta" />
  </main>
</template>

<script>
import FooterCta from '../headerFooterSections/FooterCta.vue'
import HomepageHero from './headerFooterSections/HomepageHero.vue'
import PencilBanner from '../headerFooterSections/PencilBanner.vue'

import BasicContentSection from '../bodySections/BasicContentSection/index.vue'
import AppPromoModule from '../bodySections/AppPromoModule.vue'
import CaseStudyCallout from '../bodySections/CaseStudyCallout.vue'
import GuidebookShowcase from './bodySections/GuidebookShowcase.vue'
import HomepageMajorSection from './bodySections/HomepageMajorSection/index.vue'
import ImagePromoBanner from './bodySections/ImagePromoBanner.vue'
import MarketingHighlights from '../bodySections/MarketingHighlights.vue'
import PlaceholderSection from '../bodySections/PlaceholderSection.vue'
import QuoteCallout from '../bodySections/QuoteCallout.vue'
import ResourceCards from './bodySections/ResourceCards/index.vue'

export default {
  components: {
    FooterCta,
    HomepageHero,
    PencilBanner,
  },
  props: {
    content: Object,
  },
  computed: {
    bodySectionContents() {
      if (this.content) {
        return this.content.bodySections.map(
          (bodySection) =>
            bodySection._type === 'reference'
              ? { _key: bodySection._key, ...this.content.referencedDocs.find(({ _id }) => _id === bodySection._ref) }
              : bodySection
        )
      }
      return []
    }
  },
  provide() {
    return {
      locale: () => this.content?.locale,
    }
  },
  methods: {
    bodySectionComponent(type) {
      switch (type) {
      case 'basicContentSection':
        return BasicContentSection
      case 'appPromoModule':
        return AppPromoModule
      case 'caseStudyCallout':
        return CaseStudyCallout
      case 'guidebookShowcase':
        return GuidebookShowcase
      case 'marketingHighlights':
        return MarketingHighlights
      case 'homepageMajorSection':
        return HomepageMajorSection
      case 'imagePromoBanner':
        return ImagePromoBanner
      case 'quoteCallout':
        return QuoteCallout
      case 'resourceCards':
        return ResourceCards
      default:
        return PlaceholderSection
      }
    },
  },
  contentQuery: `
    *[_id == $id][0]{
      locale,
      pencilBanner->{ message, cta },
      hero,
      bodySections,
      footerCta->{ title, text, cta },
      'referencedDocs': *[_id in ^.bodySections[_type == 'reference']._ref],
    }
  `,
}
</script>
