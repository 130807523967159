<template>
  <ColorContext :backgroundColor="backgroundColor">
    <Container
      tagName="section"
      class="ProductCards"
    >
      <CenteredTextBox v-bind="{ content }" class="text" />
      <div v-if="content.productCards" class="cardsLineup">
        <ProductCard
          v-for="product in content.productCards"
          :key="product._key"
          :product="product"
        />
      </div>
    </Container>
  </ColorContext>
</template>

<script>
import CenteredTextBox from '../blocks/CenteredTextBox.vue'
import ColorContext from '../../elems/ColorContext.vue'
import ProductCard from './ProductCard.vue'

export default {
  name: 'ProductCards',
  components: {
    CenteredTextBox,
    ColorContext,
    ProductCard,
  },
  props: {
    content: Object,
  },
  computed: {
    backgroundColor() {
      return this.content.presentationOptions?.backgroundColor;
    },
  },
}
</script>

<style scoped>

.ProductCards {
  background-color: var(--background-color);
  padding-top: var(--spacing-15);
  padding-bottom: var(--spacing-15);
}

.cardsLineup {
  display: grid;
  gap: var(--spacing-10);
  margin-top: var(--spacing-12);
}

@media (--screen-lg) {
  .cardsLineup {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
