<template>
  <Container tagName="section" :class="[$style.OfficeContactDetails]">
    <div :class="$style.contactHead">
      <h2 :class="$style.heading">{{ content.heading }}</h2>
      <div v-if="content.body" :class="$style.body">
        <DefaultBlockContent :blocks="content.body" />
      </div>
    </div>
    <div :class="$style.contactDetails">
      <div :class="$style.text">
        <div v-if="content.contactDetails" :class="$style.body">
          <DefaultBlockContent :blocks="content.contactDetails" />
        </div>
      </div>
      <div :class="$style.googleMap" v-if="content.googleMapUrl">
        <iframe
          :src="content.googleMapUrl"
          width="100%"
          height="630"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  </Container>
</template>

<script>
import DefaultBlockContent from '../elems/DefaultBlockContent.vue'
import ColorContext from '../elems/ColorContext.vue'

export default {
  components: {
    DefaultBlockContent,
    ColorContext,
  },
  props: {
    content: Object,
  },
  computed: {
    backgroundColor() {
      return this.content.presentationOptions?.backgroundColor
    },
  },
}
</script>

<style module>
.OfficeContactDetails {
  background-color: var(--bg-color-pale-gray);
  color: var(--text-color-dark);
  padding-bottom: var(--spacing-15);
  padding-top: var(--spacing-15);
}

.contactHead .body {
  max-width: 540px;
}
.contactDetails {
  margin-top: var(--spacing-12);
}

.heading {
  color: var(--text-color-black);
  font-size: var(--font-size-10);
  font-weight: 600;
  line-height: 1.2;
}
.heading + .body {
  margin-top: var(--spacing-7);
}

.body {
  font-weight: normal;
  font-size: var(--font-size-3);
  line-height: 1.7;
}
.body div > * + * {
  margin-top: var(--spacing-6);
}
.body + .ctaWrapper {
  margin-top: var(--spacing-8);
}

.googleMap {
  margin-top: var(--spacing-15);
}
.googleMap iframe {
  border-radius: var(--border-radius);
  max-width: 100%;
}


@media (--screen-lg) {
  .contactDetails {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: var(--spacing-6);
  }
  .text {
    margin-top: 0;
    max-width: 640px;
  }
  .text .body {
    padding-right: 60px;
  }
  .googleMap {
    max-width: 630px;
  }
  .text,
  .googleMap {
    grid-row: 1;
    align-self: center;
  }
  .googleMap {
    margin-top: 0;
  }
}
</style>
