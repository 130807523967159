<template>
  <div class="PopupDefault">
    <SanityImage v-if="content.image" :asset="content.image.asset" class="image" />
    <h3 class="prompt">{{ content.prompt }}</h3>
    <p class="message">{{ content.message }}</p>
    <div @click="ctaClicked()" class="ctaWrapper">
      <SmallCtaButton v-if="content.cta" v-bind="content.cta" icon="arrow" class="cta --dark-blue" />
    </div>
    <CloseButton class="closeButton" @click="hidePopup()" />
  </div>
</template>

<script>
import CloseButton from '../../elems/CloseButton.vue'

export default {
  components: {
    CloseButton,
  },
  props: {
    content: Object,
    hidePopup: Function,
    ctaClicked: Function,
  },
}
</script>

<style scoped>

.PopupDefault {
  background-color: var(--bg-color-white);
  width: 28.75rem;
  padding: var(--spacing-8);
  border: thin solid var(--hairline-color);
  border-radius: 5px;
  border-bottom-style: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.closeButton {
  position: absolute;
  top: var(--spacing-7);
  right: var(--spacing-7);
  cursor: pointer;
}

.image {
  height: 125px;
}

.prompt {
  font-weight: 600;
  font-size: var(--font-size-2);
  color: var(--brand-color);
  text-transform: uppercase;
}

.message {
  max-width: 21rem;
  font-weight: 500;
  font-size: var(--font-size-3);
  color: var(--text-color-black);
}

.image + .prompt {
  margin-top: var(--spacing-7);
}

.prompt + .message {
  margin-top: var(--spacing-0);
}

.message + .ctaWrapper {
  margin-top: var(--spacing-7);
}
</style>
