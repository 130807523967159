<template>
  <ColorContext :backgroundColor="backgroundColor">
    <Container
      tagName="section"
      class="TabbedImageContentSection"
    >
      <CenteredTextBox v-bind="{ content }" class="text" />
      <div class="tabWrapper">
        <button
          v-for="(tab, index) in content.tabs"
          :key="index"
          class="tabButton"
          :class="currentTab === index && 'active'"
          @click="selectTab(index)"
        >
          {{ tab.title }}
        </button>
      </div>
      <div class="tabPanes">
        <TabPane
          v-for="(tab, index) in content.tabs"
          :key="index"
          :desktopImage="tab.desktopImage"
          :mobileImage="tab.mobileImage"
          :hidden="currentTab !== index"
        />
      </div>
    </Container>
  </ColorContext>
</template>

<script>
import CenteredTextBox from '../blocks/CenteredTextBox.vue'
import ColorContext from '../../elems/ColorContext.vue'
import TabPane from './TabPane.vue'

export default {
  name: 'TabbedImageContentSection',
  components: {
    CenteredTextBox,
    ColorContext,
    TabPane,
  },
  props: {
    content: Object,
  },
  data() {
    return {
      currentTab: 0,
    }
  },
  computed: {
    backgroundColor() {
      return this.content.presentationOptions?.backgroundColor;
    },
  },
  methods: {
    selectTab(index) {
      this.currentTab = index
    },
  },
}
</script>

<style scoped>

.TabbedImageContentSection {
  background-color: var(--background-color);
  color: var(--text-color);
  padding-top: var(--spacing-15);
  padding-bottom: var(--spacing-15);
}

.tabWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  column-gap: var(--spacing-2);
  margin-top: var(--spacing-8);
  margin-bottom: var(--spacing-10);
}

.tabButton {
  font-weight: 600;
  font-size: var(--font-size-3);
  min-width: 140px;
  padding: var(--spacing-2);
  color: var(--tab-color);
  border-bottom: 3px solid var(--tab-color);
}
.tabButton.active {
  color: var(--tab-color-active);
  border-bottom: 3px solid var(--tab-color-active);
}

@media (--screen-md) {
  .tabButton {
    min-width: 230px;
    font-size: var(--font-size-4);
  }
}

.tabPanes {
  margin-top: var(--spacing-10);
}

@media (--screen-lg) {
  .TabPanes {
    margin-left: auto;
    margin-right: auto;
    max-width: 1060px;
  }
}
</style>
