<template>
  <Container
    tagName="section"
    class="CollapsibleFeatureListing"
    :style="{ backgroundColor }"
  >
    <CenteredTextBox :content="contentSansCta" />
    <div
      class="featureListing"
      :class="isImageAlignedRight ? 'alignImageRight' : 'alignImageLeft'"
    >
      <div class="image" v-if="content.image">
        <SanityImage :asset="content.image.asset" />
      </div>
      <div class="features">
        <FeatureDetails
          v-for="(feature, index) in content.features"
          :key="feature._key"
          :open="index === openedIndex"
          :content="feature"
          class="featureItem"
          :selectThisFeature="() => selectFeature(index)"
        />
      </div>
    </div>
    <div class="footer">
      <CtaButton
        class="--dark-blue"
        v-if="content.cta && content.cta.text"
        v-bind="content.cta"
      />
    </div>
  </Container>
</template>

<script>
import CenteredTextBox from '../blocks/CenteredTextBox.vue'
import FeatureDetails from './FeatureDetails.vue'

export default {
  name: 'FeatureGrid',
  components: {
    CenteredTextBox,
    FeatureDetails,
  },
  props: {
    content: Object,
  },
  computed: {
    backgroundColor() {
      switch (this.content.presentationOptions?.backgroundColor) {
        case 'pale-gray':
          return 'var(--bg-color-pale-gray)'
        default:
          return 'var(--bg-color-white)'
      }
    },
    isImageAlignedRight() {
      return this.content.presentationOptions?.imageAlign === 'right'
    },
    contentSansCta() {
      const { cta, ...contentSansCta } = this.content
      return contentSansCta
    }
  },
  data() {
    return {
      openedIndex: 0,
    }
  },
  methods: {
    selectFeature(index) {
      this.openedIndex = index
    },
  },
}
</script>

<style scoped>

.CollapsibleFeatureListing {
  padding-top: var(--spacing-15);
  padding-bottom: var(--spacing-15);
  color: var(--text-color-dark);
}

.featureListing {
  margin-top: var(--spacing-12);
}

.image + .features {
  margin-top: var(--spacing-10);
}

.image img {
  border-radius: var(--border-radius);
}

.featureItem[disabled] {
  pointer-events: none;
  user-select: none;
}

.featureListing + .footer {
  margin-top: var(--spacing-13);
}

@media (--screen-lg) {

  .featureListing {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: var(--spacing-4);
  }

  .featureListing .features,
  .featureListing .image {
    grid-row: 1;
    align-self: center;
  }

  .featureListing.alignImageLeft .features {
    grid-column: 7 / span 5;
  }

  .featureListing.alignImageLeft .image {
    grid-column: 2 / span 4;
  }

  .featureListing.alignImageRight .features {
    grid-column: 2 / span 5;
  }

  .featureListing.alignImageRight .image {
    grid-column: 8 / span 4;
  }

  .featureListing .features {
    margin-top: 0;
  }

  .footer {
    text-align: center;
  }
}
</style>
