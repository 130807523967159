<template>
  <ColorContext :backgroundColor="backgroundColor">
    <Container tagName="section" class="CenteredIframe">
      <CenteredTextBox v-bind="{ content }" class="textBox" />
      <div class="iframe" v-if="content.iframeUrl">
        <iframe
          :src="content.iframeUrl"
          width="100%"
          height="900"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </Container>
  </ColorContext>
</template>

<script>
import CenteredTextBox from './blocks/CenteredTextBox.vue'
import ColorContext from '../elems/ColorContext.vue'

export default {
  components: {
    CenteredTextBox,
    ColorContext,
  },
  props: {
    content: Object,
  },
  computed: {
    backgroundColor() {
      return this.content.presentationOptions?.backgroundColor;
    },
  },
}
</script>

<style scoped>
.CenteredIframe {
  background-color: var(--background-color);
  color: var(--text-color);
  padding-bottom: var(--spacing-15);
  padding-top: var(--spacing-15);
}

.textBox + .iframe {
  margin-top: var(--spacing-14);
}

@media (--screen-lg) {
  .CenteredIframe {
    --heading-font-size: var(--font-size-9);
    align-items: center;
  }
}
</style>
