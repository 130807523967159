<template>
  <div class="BodySectionExample">
    <header class="header">
      <span class="document">Found in {{ content._docType }}#{{ content._docId }} "{{ content._docTitle }}"</span>
    </header>
    <AutoBodySection v-bind="{ content }" class="content" />
  </div>
</template>

<script>
import AutoBodySection from './bodySections/AutoBodySection.vue'

export default {
  components: {
    AutoBodySection,
  },
  props: {
    content: {
      type: Object,
      default: Object,
    },
  },
  provide() {
    return {
      locale: () => this.content?.locale,
    }
  },
}
</script>

<style scoped>
.BodySectionExample {
  background-color: #ccc;
  padding: 0.5rem;
}
.header {
  font-size: small;
}
.document {
  font-weight: 500;
}
.content {
  margin-top: 0.25rem;
  background-color: white;
}
</style>
